import { networkConfig } from "@/config/networkConfig"

/**
 *  复制到剪贴板
 */
export const copyToClipboard = (text: string) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  textarea.style.position = 'fixed';
  textarea.style.left = '-9999px';
  document.body.appendChild(textarea);
  textarea.select();
  const successful = document.execCommand('copy');
  document.body.removeChild(textarea);
  return successful;
}


/**
 * 获取资源链接
 * @param record 记录
 * @param token 用户token
 * @param view 浏览模式 编辑模式
 */
export const getResourceEditOrViewUrl =
  (record: any, token: string, bridgeOrView: 'bridge'|'view' = 'bridge'): string => {


    const vr = `${networkConfig.serverUrl}/vr/index/${to62(parseInt(record.number))}?token=${token}&t=` + Date.now();
    const map =
      `https://www.aideba.com.cn/fjdt/manage/#/extern/${bridgeOrView}?token=${token}&id=${record.originalMapId}&t=` + Date.now();

    return record.resourceType == 4 ? vr : map;

  }


/**
* 获取资源链接
* @param record 记录
*/
export const getResourceViewUrl =
  (record: any, type = "1"): string => {
    const map = `${networkConfig.serverUrl}/map/view/${to62(parseInt(record.number))}/${type}?t=` + Date.now()
    const vr = `${networkConfig.serverUrl}/vr/view/${to62(parseInt(record.number))}?t=` + Date.now();
    return record.resourceType == 4 ? vr : map;
  }

/**
 * 转62进制
 * @param number 
 * @returns 
 */
export const to62 = (number: number) => {
  const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  while (number > 0) {
    const remainder = number % 62;
    number = Math.floor(number / 62);
    result = chars[remainder] + result;
  }
  return result;
}
