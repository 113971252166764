export const MyTransactionColumns = [
  {
    title: "编号",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "资源标题",
    dataIndex: "resourceTitle",
    key: "resourceTitle",
  },
  {
    title: "资源名称",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "交易类型",
    dataIndex: "transactionType",
    key: "transactionType",
  },
  {
    title: "资源类型",
    key: "resourceType",
    dataIndex: "resourceType",
  },
  {
    title: "适用城市",
    key: "applicableCity",
    dataIndex: "applicableCity",
  },
  {
    title: "有效时间",
    key: "usableMonths",
    dataIndex: "usableMonths",
  },
  {
    title: "交易金额",
    key: "transactionAmount",
    dataIndex: "transactionAmount",
  },
  {
    title: "交易对象",
    key: "transactionParty",
    dataIndex: "transactionParty",
  },
  {
    title: "交易时间",
    key: "transactionTime",
    dataIndex: "transactionTime",
  },
];



export const MyResourceColumns = [
  {
    title: "编号",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "资源名称",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "资源来源",
    dataIndex: "sourceType",
    key: "sourceType",
  },
  {
    title: "资源类型",
    key: "resourceType",
    dataIndex: "resourceType",
  },
  {
    title: "资源状态",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "适用城市",
    key: "applicableCity",
    dataIndex: "applicableCity",
  },
  {
    title: "最后更新时间",
    key: "lastModificationTime",
    dataIndex: "lastModificationTime",
  },
  {
    title: "到期时间",
    key: "expiredTime",
    dataIndex: "expiredTime",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
  },
];

export const MapAssetsColumns = [
  {
    title: "编号",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "名称",
    dataIndex: "name",
    width: 500,
    key: "name",
  },
  {
    title: "资源来源",
    dataIndex: "sourceType",
    key: "sourceType",
  },
  {
    title: "资源类型",
    key: "assetLevel",
    dataIndex: "assetLevel",
  },
  {
    title: "适用城市",
    key: "applicableCity",
    dataIndex: "applicableCity",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
  },
];

export const WaitPublishColumns = [
  {
    title: "编号",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "资源名称",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "来源",
    dataIndex: "sourceType",
    key: "sourceType",
  },
  {
    title: "资源类型",
    key: "resourceType",
    dataIndex: "resourceType",
  },
  {
    title: "适用城市",
    key: "applicableCity",
    dataIndex: "applicableCity",
  },
  {
    title: "最后更新时间",
    key: "lastModificationTime",
    dataIndex: "lastModificationTime",
  }
];


export const MyPublishColumns = [
  {
    title: "编号",
    dataIndex: "number",
    key: "number",
  },
  {
    title: "资源标题",
    dataIndex: "publishTitle",
    key: "publishTitle",
  },
  {
    title: "资源名称",
    dataIndex: "resourceName",
    key: "resourceName",
  },
  {
    title: "资源类型",
    dataIndex: "resourceType",
    key: "resourceType",
  },
  {
    title: "适用城市",
    key: "applicableCity",
    dataIndex: "applicableCity",
  },
  {
    title: "有效时间",
    key: "usableMonths",
    dataIndex: "usableMonths",
  },
  {
    title: "销售状态",
    key: "saleStatus",
    dataIndex: "saleStatus",
  },
  {
    title: "定价",
    key: "price",
    dataIndex: "price",
  },
  {
    title: "交易量",
    key: "count",
    dataIndex: "count",
  },
  {
    title: "交易总额",
    key: "totalAmount",
    dataIndex: "totalAmount",
  },

  {
    title: "发布时间",
    key: "publishTime",
    dataIndex: "publishTime",
  },
  {
    title: "操作",
    key: "action",
    dataIndex: "action",
  },
];