import CryptoJS from 'crypto-js';

const keyHex = '000102030405060708090a0b0c0d0e0f';
const ivHex = '101112131415161718191a1b1c1d1e1f';

const CryptoUtils = {
    /**
     * 加密方法
     * @param plaintext 
     * @returns 
     */
    encrypt(plaintext: string | null): string | null {
        if (!plaintext) return null;
        const key = CryptoJS.enc.Hex.parse(keyHex);
        const iv = CryptoJS.enc.Hex.parse(ivHex);

        const ciphertext = CryptoJS.AES.encrypt(plaintext, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return ciphertext.toString();
    },

    /**
     *  解密方法
     * @param ciphertext 
     * @returns 
     */
    decrypt(ciphertext: string | null): string | null {
        if (!ciphertext) return null;
        const key = CryptoJS.enc.Hex.parse(keyHex);
        const iv = CryptoJS.enc.Hex.parse(ivHex);

        const decryptedBytes = CryptoJS.AES.decrypt(ciphertext, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });

        return decryptedBytes.toString(CryptoJS.enc.Utf8);
    }
};

export default CryptoUtils;