<template>
  <div class="main">
    <div class="search-condition">
      <div style="width: 100%">
        <span class="condition-title">资源类型：</span>
        <a-select
          v-model:value="resourceType"
          :allow-clear="true"
          :options="resourceTypeOptions"
          @change="selectChange"
          placeholder="请选择资源类型"
          style="width: 200px"
        ></a-select>
        &nbsp;&nbsp;&nbsp;
        <span class="condition-title">交易类型：</span>
        <a-select
          v-model:value="transactionType"
          :allow-clear="true"
          :options="rransactionTypeOptions"
          @change="selectChange"
          placeholder="请选择交易类型"
          style="width: 200px"
        ></a-select>
        &nbsp;&nbsp;&nbsp;
        <span class="condition-title">资源编码：</span>
        <a-input
          style="width: 200px"
          :allowClear="true"
          placeholder="请选填写源编码"
          v-model:value="sourceNumber"
        ></a-input>
        <a-button type="primary" @click="query">查询</a-button>
      </div>
    </div>
    <div class="sale-info">
      <span
        >累计出售：<b class="b1">￥{{ outgoing }}</b></span
      ><span style="position: relative; left: 20px"
        >累计购入：<b class="b2">￥{{ incoming }}</b></span
      >
    </div>

    <div style="padding: 0 10px">
      <a-table
        class="ant-table-striped"
        :columns="MyTransactionColumns"
        :data-source="dataSource"
        :pagination="pagination"
        @change="handlePaginationChange"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'resourceTitle'">
            <a @click="viewResource(record.number)">{{ record[column.key] }}</a>
          </template>
          <template v-else-if="column.key === 'transactionType'">
            <span>
              <a-tag :color="transactionTypeData[record.transactionType][0]">{{
                transactionTypeData[record.transactionType][1]
              }}</a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'usableMonths'">
            <span>
              <a-tag :color="usableMonths[record.usableMonths][0]">{{
                usableMonths[record.usableMonths][1]
              }}</a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'resourceType'">
            <span>
              <a-tag :color="resourceTypeData[record.resourceType][0]">{{
                resourceTypeData[record.resourceType][1]
              }}</a-tag>
            </span>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal
      class="no-border-radius"
      width="100%"
      height="100%"
      :style="{
        width: '100%',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showLoadMap"
    >
      <div style="height: 92vh">
        <iframe
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          :key="iframeKey"
          scrolling="no"
          :src="url"
        ></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import {
  ResourceTypeOptions,
  TransactionTypeOptions,
} from "@/shared/models/select-option";
import type { SelectProps } from "ant-design-vue";
import { getTransactionRecordPageList, getBelongToMeByNumber } from "@/api/index";
import { MyTransactionColumns } from "@/shared/models/table-columns";
import dayjs from "dayjs";
import { getResourceEditOrViewUrl } from "@/shared/utils";
import {
  ResourceTypeData,
  TransactionTypeData,
  UsableMonths,
} from "@/shared/models/tag-data";
import store from "@/store";

const usableMonths = ref(UsableMonths);
const resourceTypeOptions = ref<SelectProps["options"]>(ResourceTypeOptions);
const rransactionTypeOptions = ref<SelectProps["options"]>(TransactionTypeOptions);
const dataSource = ref<any[]>([]);
const resourceType = ref(null);
const transactionType = ref(null);
const incoming = ref(0);
const outgoing = ref(0);
const sourceNumber = ref(null);
const resourceTypeData = ref(ResourceTypeData);
const transactionTypeData = ref(TransactionTypeData);
const showLoadMap = ref(false);
const url = ref("");
const iframeKey = ref(0);

/**
 *
 * 浏览资源
 */
const viewResource = async (number: string) => {
  const res: any = await getBelongToMeByNumber(number);
  if (res.success) {
    url.value = getResourceEditOrViewUrl(
      res.result,
      store.getters["auth/getToken"],
      "view"
    );
    showLoadMap.value = true;
    iframeKey.value++;
  }
};

const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: true,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});

const handlePaginationChange = (page: any) => {
  pagination.value.current = page.current;
  pagination.value.pageSize = page.pageSize;
  query();
};

const getTransactionRecordList = async (data: any) => {
  const res: any = await getTransactionRecordPageList(
    data,
    pagination.value.current,
    pagination.value.pageSize
  );

  if (res.result) {
    dataSource.value = res.result.items.map((item: any) => {
      item.transactionTime = dayjs(item.transactionTime).format("YYYY-MM-DD HH:mm");
      return item;
    });

    incoming.value = res.result.incoming.toLocaleString();
    outgoing.value = res.result.outgoing.toLocaleString();
    pagination.value.total = res.result.totalCount;
  }
};

const selectChange = () => {
  pagination.value.current = 1;
  query();
};

const query = () => {
  getTransactionRecordList({
    resourceType: resourceType.value,
    transactionType: transactionType.value,
    number: sourceNumber.value,
  });
};

query();
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-width: 980px;
  height: 100%;
  background-color: #fff;
}
.sale-info {
  padding-left: 20px;
  width: 100%;
  height: 40px;

  span {
    display: inline-block;
    width: 200px;
  }

  .b1 {
    color: #39ae00;
    font-weight: normal;
  }

  .b2 {
    color: #ff4d4f;
    font-weight: normal;
  }
}
.search-condition {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  position: relative;
  top: 30px;
  height: 100px;
  width: 100%;

  button {
    margin-left: 10px;
  }
}
</style>
