import { httpRequest } from "../http/request";

const controllerName = 'myResource'

/**
 * 获取我的资源列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getMyResourcePageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/list?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data,
            sort: 'Number'
        }
    });
}


/**
 * 获取矩客星的资源列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getMapAssetsRecordsPageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/mapAssetsRecords?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data
        }
    });
}


/**
 * 获取我的待发布资源列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getMyWaitPublishResourcePageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/waitPublishList?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data,
            sort: 'Number'
        }
    });
}

/**
 * 创建资源
 * @param data
 * @returns {*}
 */
export function createMyResource(data: any) {
    return httpRequest({
        url: `/${controllerName}/create`,
        method: "post",
        data: {
            ...data
        }
    });
}


/**
 *  获取单个资源
 * @param number 
 * @returns 
 */
export function getMyResourceByNumber
    (number: string) {
    return httpRequest({
        url: `/${controllerName}/getByNumber?number=${number}`,
        method: "get"
    });
}

/**
 *  获取属我购买的资源
 * @param number 
 * @returns 
 */
export function getBelongToMeByNumber
    (number: string) {
    return httpRequest({
        url: `/${controllerName}/getBelongToMeByNumber?number=${number}`,
        method: "get"
    });
}



/**
 * 查看资源是否被购买
 * @param number 
 * @returns 
 */
export function hasBought(number: string) {
    return httpRequest({
        url: `/${controllerName}/hasBought?number=${number}`,
        method: "get"
    });
}


/**
 * 删除资源(注：物理删除)
 * @param number 
 * @returns 
 */
export function deleteMyResource(id: string) {
    return httpRequest({
        url: `/${controllerName}/delete`,
        method: "post",
        data: {
            id
        }
    });
}


/**
 *  获取单个资源
 * @param number 
 * @returns 
 */
export function getMyResourceById (id:string) {
    return httpRequest({
        url: `/${controllerName}/getById?id=${id}`,
        method: "get"
    });
}



/**
 * 更新资源
 * @param number 
 * @returns 
 */
export function updateMyResource(data:any) {
    return httpRequest({
        url: `/${controllerName}/update`,
        method: "post",
        data: {
            ...data
        }
    });
}


/**
 * 载入地图
 * @param mapId 地图id 
 * @param cityId 城市id
 * @returns 
 */
export function setMyMapToUsed(number:string){
    return httpRequest({
        url: `/${controllerName}/setMapToUsed?number=${number}`,
        method: "get"
    });
}


/**
 * 恢复地图，回滚到购入时的状态
 * @param number 资源编号
 * @returns 
 */

export function recoveryMyMap (number:string) {
    return httpRequest({
        url: `/${controllerName}/recovery`,
        method: "post",
        data: {
            number
        }
    });
}


