import { httpRequest } from "../http/request";

const controllerName = 'myPublish'

/**
 * 获取我的发布列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getMyPublishPageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/list?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data,
            sort: 'Number'
        }
    });
}

/**
 * 发布资源
 * @param data 
 * @returns 
 */
export function createMyPublish(data: any) {
    return httpRequest({
        url: `/${controllerName}/create`,
        method: "post",
        data: {
            ...data
        }
    });
}