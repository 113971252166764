import { httpRequest } from "../http/request";

const controllerName = 'resourceInfo'

/**
 * 获取资源列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getResourceInfoPageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/list?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data,
            sort: 'Number'
        }
    });
}



/**
 * 获取资源
 * @param id
 * @returns {*}
 */
export function getResourceInfoByNumber(number: string) {
    return httpRequest({
        url: `/${controllerName}/getByNumber?number=${number}`,
        method: "get"
    });
}




/**
 * 设置销售状态
 * @param id
 * @returns {*}
 */
export function setResourceInfoSaleStatus(number: string,status:number) {
    return httpRequest({
        url: `/${controllerName}/setSaleStatus?number=${number}&status=${status}`,
        method: "get"
    });
}