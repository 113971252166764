<template>
  <div class="main-content">
    <div class="module-content">
      <div class="module-item item1" @click="createMap()">
        <div class="text">创建地图</div>
      </div>

      <div class="module-item item2" @click="createVr()">
        <div class="text">创建VR</div>
      </div>

      <div class="module-item item3" @click="showPublishModal">
        <div class="text">发布交易</div>
      </div>

      <div class="module-item item4" @click="toMyTrans">
        <div class="text">交易记录</div>
      </div>
    </div>
    <a-modal
      width="1024px"
      min-width="1024px"
      class="no-border-radius"
      :closable="false"
      centered
      :footer="null"
      @close="handleModalClose"
      v-model:open="modalVisible"
    >
      <component
        :is="currentComponent"
        :key="componentKey"
        @close-modal="handleModalClose"
      ></component>
    </a-modal>

    <CreateMap
      :show-model="showCreateMap"
      @close="closeCreateMap"
      @ok="createMapOK"
    ></CreateMap>
    <CreateVR
      :show-model="showCreateVr"
      @close="closeCreateVr"
      @ok="createVrOK"
    ></CreateVR>

    <a-modal
      class="no-border-radius"
      width="100%"
      height="100%"
      :style="{
        width: '100%',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showLoadMap"
    >
      <div style="height: 92vh">
        <iframe
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          :key="iframeKey"
          scrolling="no"
          :src="url"
        ></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import ResourcePublish from "@/views/console/my-home/resource-publish/index.vue";
import CreateMap from "@/views/console/create-map/index.vue";
import CreateVR from "@/views/console/create-vr/index.vue";
import { getResourceEditOrViewUrl } from "@/shared/utils";
import store from "@/store";

const currentComponent = ref<typeof ResourcePublish | null>(null);
currentComponent.value = ResourcePublish;
const componentKey = ref(0);

const router = useRouter();
const modalVisible = ref(false);

const showCreateMap = ref(false);
const showCreateVr = ref(false);

const url = ref("");
const iframeKey = ref(0);
const showLoadMap = ref(false);

const createMap = () => {
  showCreateMap.value = true;
};

const createVr = () => {
  showCreateVr.value = true;
};

const closeCreateMap = () => {
  showCreateMap.value = false;
};

const closeCreateVr = () => {
  showCreateVr.value = false;
};

const createVrOK = async () => {
  setTimeout(() => {
    router.push({
      name: "MyResource",
    });
  }, 500);
};

/**
 *
 * 设计地图
 */
const designMap = (record: any) => {
  url.value = getResourceEditOrViewUrl(record, store.getters["auth/getToken"], "bridge");
  showLoadMap.value = true;
  iframeKey.value++;
};

const createMapOK = async (record: any) => {
  designMap(record);
};

const toMyTrans = () => {
  router.push({
    name: "MyTransaction",
  });
};

const showPublishModal = () => {
  modalVisible.value = true;
  componentKey.value++;
};

const handleModalClose = () => {
  modalVisible.value = false;
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}
.module-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 200px;
  width: 1120px;
}

.module-item {
  width: 200px;
  height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  user-select: none;

  .text {
    position: relative;
    top: 130px;
    width: 72px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    text-align: center;
  }

  &.item1 {
    background: #f8f8f8 url("../../../assets/images/create-map.png") no-repeat center
      center;
    background-size: 60px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../assets/images/create-map-active.png") no-repeat
        center center;
    }
  }

  &.item2 {
    background: #f8f8f8 url("../../../assets/images/create-vr.png") no-repeat center
      center;
    background-size: 60px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../assets/images/create-vr-active.png") no-repeat
        center center;
    }
  }

  &.item3 {
    background: #f8f8f8 url("../../../assets/images/create-publish.png") no-repeat center
      center;
    background-size: 60px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../assets/images/create-publish-active.png")
        no-repeat center center;
    }
  }

  &.item4 {
    background: #f8f8f8 url("../../../assets/images/create-tran.png") no-repeat center
      center;
    background-size: 60px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../assets/images/create-tran-active.png") no-repeat
        center center;
    }
  }
}
</style>

<style lang="scss">
.no-border-radius .ant-modal-content {
  border-radius: 0 !important;
}

.ant-tag {
  border-radius: 10px !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:last-child {
  border-start-end-radius: 0px !important;
}

.ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  border-start-start-radius: 0px !important;
}
.ant-table-tbody > tr > td,
ant-table-cell-row-hover:first-child {
  border-end-start-radius: 0px !important;
  border-start-start-radius: 0px !important;
}

.ant-table-tbody > tr > td,
ant-table-cell-row-hover:last-child {
  border-end-end-radius: 0px !important;
  border-start-end-radius: 0px !important;
}
</style>
