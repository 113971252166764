import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/reset.css';
import zhCN from 'ant-design-vue/es/locale/zh_CN';

window.onerror = function (message, source, lineno, colno, error) {
    console.error('Uncaught runtime error:', message, source, lineno, colno, error);
};


const app = createApp(App);
app.config.globalProperties.$locale = zhCN;
app.config.errorHandler = function (err, vm, info) { console.error(err, vm, info); };
app.use(Antd).use(store).use(router).mount('#app');



window.addEventListener('storage', async function(event) {
    if (event.key === 'uid' && event.newValue === null) {
        await store.dispatch("auth/logout");
    }
});
