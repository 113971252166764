<template>
  <div>
    <a-steps
      :current="current"
      :items="items"
      type="navigation"
      :percent="percent"
    ></a-steps>
    <div class="steps-content">
      <div class="module-content" v-show="current == 0">
        <div
          @click="swicthTradType('map')"
          :class="[
            'item1',
            { 'module-item-active': tradType == 'map' },
            { 'module-item': tradType == 'vr' || tradType == null },
          ]"
        >
          <div class="text">地图交易</div>
        </div>

        <div
          @click="swicthTradType('vr')"
          :class="[
            'item2',
            { 'module-item-active': tradType == 'vr' },
            { 'module-item': tradType == 'map' || tradType == null },
          ]"
        >
          <div class="text">VR交易</div>
        </div>
      </div>
      <div class="module-content1" v-show="current == 1">
        <a-table
          :columns="WaitPublishColumns"
          :data-source="dataSource"
          :pagination="pagination"
          @change="handlePaginationChange"
          size="middle"
          style="width: 970px; height: 385px"
          :row-selection="{
            type: 'radio',
            selectedRowKeys: state.selectedRowKeys,
            onChange: onSelectChange,
          }"
          :customRow="loadCustomRow"
        >
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'resourceName'">
              <a>{{ record.resourceName }}</a>
            </template>
            <template v-else-if="column.key === 'sourceType'">
              <span>
                <a-tag :color="resourceTypeData[record.resourceType][0]">{{
                  resourceTypeData[record.resourceType][1]
                }}</a-tag>
              </span>
            </template>
            <template v-else-if="column.key === 'resourceType'">
              <span>
                <a-tag :color="sourceTypeData[record.sourceType][0]">{{
                  sourceTypeData[record.sourceType][1]
                }}</a-tag>
              </span>
            </template>
          </template>
        </a-table>
      </div>

      <div class="module-content1" v-show="current == 2">
        <div style="width: 450px; height: 420px">
          <iframe
            style="width: 100%; height: 100%; border: none; overflow: hidden"
            scrolling="no"
            :key="iframeKey"
            :src="url"
          ></iframe>
        </div>
        <div style="width: 450px; height: 420px; margin-left: 20px; text-align: left">
          <div class="publish-content">
            <div style="width: 40%"><b>资源类型：</b>{{ type }}</div>
            <div style="width: 60%"><b>适用城市：</b>{{ city }}</div>
          </div>
          <div class="publish-content">
            <b>发布标题：</b>
            <a-input
              show-count
              :maxlength="20"
              style="width: 285px; height: 32px"
              ref="titleInput"
              v-model:value="title"
              placeholder="请输入标题"
            />
          </div>
          <div class="publish-content">
            <b>出售价格：</b>
            <a-input-number
              style="width: 100px; height: 32px"
              ref="priceInput"
              :controls="false"
              @keydown="handleKeyDown"
              :min="0"
              :step="0.01"
              v-model:value="price"
              placeholder="请输入价格"
            />&nbsp;&nbsp;元/份<span style="color: red; margin-left: 20px"
              >注：0 或空代表免费</span
            >
          </div>
          <div class="publish-content">
            <b>有效时间：</b>
            <a-radio-group v-model:value="months">
              <a-radio :value="0">长期</a-radio>
              <a-radio :value="12">一年</a-radio>
              <a-radio :value="3">三个月</a-radio>
              <a-radio :value="1">一个月</a-radio>
            </a-radio-group>
          </div>
          <div class="publish-content">
            <b>资源封面：</b>
            <a-upload
              :show-upload-list="true"
              :file-list="fileList"
              :before-upload="beforeUpload"
              @remove="handleRemove"
            >
              <a-button>
                <upload-outlined></upload-outlined>
                上传封面(350*220)
              </a-button>
            </a-upload>
          </div>
          <div class="mainze">
            <div class="wenzi">
              <span>注意事项：</span
              ><br />1、请确保您拥有当前发布资源的全部完整知识产权。若因知识产权造成的纠纷<em
                style="padding-left: 20px; font-style: normal"
                >和后果与矩客星平台无关；</em
              ><br />
              2、交易一旦发布，将展示在矩客星交易大厅供浏览和出售，发布后无法修改价<em
                style="padding-left: 20px; font-style: normal"
                >格等信息；</em
              ><br />
              3、在推广期内矩客星平台不参与交易分成；<br />
              4、交易双方不得私下交易，若私下交易权益受损与矩客星平台无关；<br />
              5、若交易过程中有任何疑问，可加矩客星平台客服微信”aidesoft“沟通反馈。<br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="steps-action">
      <a-button v-if="current > 0" style="margin-right: 8px" @click="prev"
        >上一步</a-button
      >
      <a-button v-if="current < steps.length - 1" type="primary" @click="next"
        >下一步</a-button
      >
      <a-button v-if="current == steps.length - 1" type="primary" @click="publish">
        提交发布
      </a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import { message } from "ant-design-vue";
import { WaitPublishColumns } from "@/shared/models/table-columns";
import {
  getMyWaitPublishResourcePageList,
  createMyPublish,
  getMyResourceByNumber,
  uploadImage,
} from "@/api/index";
import dayjs from "dayjs";
import { useRouter } from "vue-router";
import { ResourceTypeData, SourceTypeData } from "@/shared/models/tag-data";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { getResourceEditOrViewUrl } from "@/shared/utils";
import store from "@/store";
import type { UploadProps } from "ant-design-vue";
const user = store.getters["auth/getUser"];

const months = ref(12);
const router = useRouter();
const current = ref<number>(0);
const percent = ref<number>(33);
type ResourceType = "map" | "vr";
const dataSource = ref<any[]>([]);
const tradType = ref<ResourceType | null>(null);
const title = ref<string>("");
const price = ref<number>(0);
const iframeKey = ref(0);
const url = ref("");

const titleInput = ref<HTMLInputElement | null>(null);
const priceInput = ref<HTMLInputElement | null>(null);
const resourceTypeData = ref(ResourceTypeData);
const sourceTypeData = ref(SourceTypeData);
const city = ref("");
const type = ref("");

type Key = string | number;
type Keys = Key[];
const state = reactive<{ selectedRowKeys: Keys }>({ selectedRowKeys: [] });

const pagination = ref({
  current: 1,
  pageSize: 7,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: false,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});

const emits = defineEmits(["close-modal"]);

const fileList = ref<UploadProps["fileList"]>([]);

const handleRemove: UploadProps["onRemove"] = () => {
  fileList.value = [];
};

const beforeUpload: UploadProps["beforeUpload"] = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("请上传JPG/PNG文件，且大小不能超过200KB");
    return false;
  }

  if (file.size > 200 * 1024) {
    message.error("图片大小不能超过200KB");
    return false;
  }

  fileList.value = [file];
  return false;
};

const loadCustomRow = (record: any) => {
  return {
    onClick: () => {
      if (record.disabled) return;
      state.selectedRowKeys = [record.key];
    },
  };
};

const handleKeyDown = (event: any) => {
  const allowedKeys = [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Tab",
    "Home",
    "End",
  ];
  const key = event.key;

  if (!/\d/.test(key) && key !== "." && !allowedKeys.includes(key)) {
    event.preventDefault();
  }

  // 确保只有一个小数点
  if (key === "." && event.target.value.includes(".")) {
    event.preventDefault();
  }
};

const publish = () => {
  if (state.selectedRowKeys.length == 0) {
    message.error("请退回上一步选择出售标的");
    return;
  }
  if (title.value == "") {
    message.error("标题不能为空，请输入标题");
    titleInput.value?.focus();
    return;
  }
  if (price.value < 0) {
    message.error("价格不能小于0，请输入价格");
    priceInput.value?.focus();
    return;
  }
  showPromiseConfirm();
};

const showPromiseConfirm = () => {
  Modal.confirm({
    title: "确认发布",
    icon: createVNode(ExclamationCircleOutlined),
    content:
      "发布的资源将会在交易大厅公开展示，供用户查看和交易。请确保标题描述清楚，以便让用户快速理解您的意图。",
    async onOk() {
      //如果有封面的话，要先上传封面
      let imageUrl = "";
      if (fileList.value && fileList.value.length > 0) {
        const formData = new FormData();
        formData.append("file", fileList.value[0] as any);
        const res: any = await uploadImage(formData);
        if (res.success) {
          imageUrl = res.result.url;
        } else {
          message.error("封面上传失败，请重试");
          return;
        }
      }

      const data = {
        publishTitle: title.value,
        price: price.value,
        imageUrl: imageUrl,
        avatarUrl: user.avatar,
        usableMonths: months.value,
        number: state.selectedRowKeys[0],
      };
      const res: any = await createMyPublish(data);
      if (res.success) {
        message.success("资源已发布成功");

        emits("close-modal");
        router.push("/my-publish");

        return await new Promise((resolve) => {
          resolve(res);
        });
      }
    },
  });
};

const loadResource = async (number: string) => {
  var res: any = await getMyResourceByNumber(number);

  if (res.success) {
    loadResourceToView(res.result);
    city.value = res.result.applicableCity;
    type.value = ResourceTypeData[res.result.resourceType][1];
  }
};

const loadResourceToView = (record: any) => {
  url.value = getResourceEditOrViewUrl(record, store.getters["auth/getToken"], "view");
  iframeKey.value++;
};

const onSelectChange = (selectedRowKeys: Key[]) => {
  state.selectedRowKeys = selectedRowKeys;
};

const swicthTradType = (type: ResourceType) => {
  state.selectedRowKeys = [];
  pagination.value.current = 1;
  tradType.value = type;
};

const handlePaginationChange = (page: any) => {
  pagination.value.current = page.current;
  pagination.value.pageSize = page.pageSize;
  query();
};

const query = () => {
  getMyResourceList({ tradType: tradType.value });
};

const getMyResourceList = async (data: any) => {
  const res: any = await getMyWaitPublishResourcePageList(
    data,
    pagination.value.current,
    pagination.value.pageSize
  );

  if (res.success) {
    dataSource.value = res.result.items.map((item: any) => {
      item.key = item.number;
      item.lastModificationTime = dayjs(item.publishTime).format("YYYY-MM-DD HH:mm");
      return item;
    });
    pagination.value.total = res.result.totalCount;
  }
};

const next = () => {
  if (current.value == 0 && tradType.value == null) {
    message.error("请选择交易类型");
    return;
  }
  if (current.value == 1 && state.selectedRowKeys.length == 0) {
    message.error("请选择出售标的");
    return;
  }

  if (current.value == 0) {
    query();
  }
  current.value++;
  percent.value = 100 / (3 - current.value);

  if (current.value == 2) {
    loadResource(state.selectedRowKeys[0] as string);
  }
};

const prev = () => {
  current.value--;
  percent.value = 100 / (3 - current.value);
};

const steps = ["第一步、选择交易类型", "第二步、选择出售标的", "第三步、填写出信信息"];
const items = steps.map((item) => ({ key: item, title: item }));
</script>

<style lang="scss" scoped>
.publish-content {
  height: 40px;
  line-height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.steps-content {
  min-height: 450px;
  text-align: center;
  width: 1024px;
  height: 450px;
  padding-top: 30px;
}

.steps-action {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.module-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  min-width: 900px;
}

.module-content1 {
  display: flex;
  align-items: center;
}

.module-item {
  width: 200px;
  height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  user-select: none;

  .text {
    position: relative;
    top: 130px;
    width: 72px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    text-align: center;
  }

  &.item1 {
    background: #f8f8f8 url("../../../../assets/images/publish-map.png") no-repeat center
      center;
    background-size: 60px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../../assets/images/publish-map-active.png")
        no-repeat center center;
    }
  }

  &.item2 {
    background: #f8f8f8 url("../../../../assets/images/publish-vr.png") no-repeat center
      center;
    background-size: 60px;
    margin-left: 100px;

    &:hover {
      cursor: pointer;
      color: #2353f3;
      border: 1px solid #2353f3;
      background: #f2f5ff url("../../../../assets/images/publish-vr-active.png") no-repeat
        center center;
    }
  }
}

.module-item-active {
  width: 200px;
  height: 200px;
  border: 1px solid #d7d7d7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  user-select: none;

  .text {
    position: relative;
    top: 130px;
    width: 72px;
    height: 25px;
    line-height: 25px;
    font-size: 18px;
    text-align: center;
    color: #2353f3;
  }

  &.item1 {
    background: #f8f8f8 url("../../../../assets/images/publish-map.png") no-repeat center
      center;
    background-size: 60px;

    cursor: pointer;
    color: #2353f3;
    border: 1px solid #2353f3;
    background: #f2f5ff url("../../../../assets/images/publish-map-active.png") no-repeat
      center center;
  }

  &.item2 {
    background: #f8f8f8 url("../../../../assets/images/publish-vr.png") no-repeat center
      center;
    background-size: 60px;
    margin-left: 100px;

    cursor: pointer;
    color: #2353f3;
    border: 1px solid #2353f3;
    background: #f2f5ff url("../../../../assets/images/publish-vr-active.png") no-repeat
      center center;
  }
}

.mainze {
  width: 505px;
  height: 218px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  .wenzi {
    width: 487px;
    height: 218px;
    font-size: 14px;
    color: #595959;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    padding-top: 8px;

    span {
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

:deep(.ant-upload-list) {
  float: right;
  margin-left: 10px;
}

:deep(.ant-upload-list-item-name) {
  max-width: 180px;
}
</style>
