<template>
  <div>
    <header-tag></header-tag>
    <div class="container">
      <div class="form-row">
        <div class="label">
          <div style="display: flex">
            <div class="biao"></div>
            <div class="register">供应商注册</div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="label"><span>*</span>实体类型：</div>
        <div class="input">
          <a-radio-group class="group" v-model:value="type" name="radioGroup">
            <a-radio value="1">公司</a-radio>
            <a-radio value="2">工作室/团队</a-radio>
            <a-radio value="3">个人</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="form-row">
        <div class="label"><span>*</span>实体名称：</div>
        <div class="input">
          <a-input
            allow-clear
            style="width: 234px"
            placeholder="请选填写实体名称"
            v-model:value="name"
            show-count
            :maxlength="20"
          ></a-input>
        </div>
      </div>
      <div class="form-row">
        <div class="label"><span>*</span>企业信用代码/居民身份证：</div>
        <div class="input">
          <a-input
            allow-clear
            style="width: 234px"
            placeholder="请选填写信用代码/居民身份证"
            v-model:value="uid"
            show-count
            :maxlength="18"
          ></a-input>
        </div>
      </div>
      <div class="form-row">
        <div class="label"><span>*</span>手机：</div>
        <div class="input">
          <a-input
            allow-clear
            style="width: 234px"
            placeholder="请输入11位手机号"
            v-model:value="mobile"
            show-count
            :maxlength="11"
          ></a-input>
        </div>
      </div>
      <div class="form-row">
        <div class="label"><span>*</span>验证码：</div>
        <div class="input">
          <div>
            <a-input
              allow-clear
              style="width: 160px"
              placeholder="请输入验证码"
              v-model:value="code"
              :maxlength="6"
            ></a-input>
            <a-button
              type="primary"
              style="margin-left: 10px"
              :disabled="disableButton"
              @click="getMobileCode"
              >{{ codeText }}</a-button
            >
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="label">备注：</div>
        <div class="input">
          <a-textarea
            style="max-width: 300px"
            placeholder="请选填写备注"
            allow-clear
            show-count
            :maxlength="50"
            v-model:value="remark"
          ></a-textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="label"></div>
        <div class="input">
          <a-button
            type="primary"
            style="margin-right: 10px"
            :loading="loading"
            @click="submit"
            >提交</a-button
          ><a-button @click="reset">重置</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import HeaderTag from "@/components/HeaderTag.vue";
import { ref } from "vue";
import { message } from "ant-design-vue";
import { register, getCode } from "@/api/index";
import { useRouter } from "vue-router";

const router = useRouter();
const type = ref("1");
const code = ref("");
const mobile = ref("");
const uid = ref("");
const name = ref("");
const remark = ref("");
const codeText = ref("发送");
const disableButton = ref(false);
const countDownSeconds = ref(60);

const loading = ref(false);

const reset = () => {
  type.value = "1";
  name.value = "";
  mobile.value = "";
  uid.value = "";
  code.value = "";
  remark.value = "";
};

const submit = async () => {
  if (name.value.length == 0) {
    message.error("请输入实体名称");
    return;
  }

  if (uid.value.length == 0) {
    message.error("请输入信用代码/身份证");
    return;
  }

  if (uid.value.length != 18) {
    message.error("请输入18位信用代码/身份证");
    return;
  }

  if (mobile.value.length == 0) {
    message.error("请输入11位手机号");
    return;
  }

  if (!/^1[3-9][0-9]{9}$/.test(mobile.value)) {
    message.error("请输入正确手机号码");
    return;
  }

  if (code.value.length == 0) {
    message.error("请输入验证码");
    return;
  }

  loading.value = true;
  const data = {
    userType: type.value,
    name: name.value,
    mobile: mobile.value,
    identity: uid.value,
    code: code.value,
    remark: remark.value,
  };

  const res: any = await register(data);
  loading.value = false;
  if (res.success) {
    message.success("注册成功，请使用手机号和默认密码888888登录");
    setTimeout(() => {
      router.push({
        name: "Login",
        query: { redirect: "/my-home" },
      });
    }, 1000);
  }
};

const getMobileCode = async () => {
  if (!/^1[3456789][0-9]{9}$/.test(mobile.value)) {
    message.error("请输入正确手机号码");
    return;
  }

  disableButton.value = true;
  codeText.value = `重发 (${countDownSeconds.value}s)`;

  const intervalId = setInterval(() => {
    countDownSeconds.value--;
    codeText.value = `重发 (${countDownSeconds.value}s)`;
    if (countDownSeconds.value === 0) {
      clearInterval(intervalId);
      disableButton.value = false;
      codeText.value = "发送";
      countDownSeconds.value = 60;
    }
  }, 1000);

  const result: any = await getCode(mobile.value);
  if (result.success) {
    message.success("短信已发送，请注意查收");
  } else {
    message.error(result.message);
  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0 0 20px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 10px;
  min-height: 50px;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
}

:deep(.group) {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.label {
  width: 30%;
  max-width: 200px;
  padding: 5px;
  box-sizing: border-box;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  display: flex;

  span {
    color: #ff0000;
    margin-right: 5px;
  }

  .biao {
    width: 4px;
    height: 16px;
    background: #2353f3;
    border-radius: 2px;
    text-align: right;
    margin-right: 10px;
  }

  .register {
    font-weight: 500;
    font-size: 18px;
    color: #262626;
    text-align: right;
    font-style: normal;
  }
}

.input {
  width: 70%;
  padding: 5px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .label {
    justify-content: flex-start;
    width: 100%;
  }
  .input {
    width: 100%;
  }
}
</style>
