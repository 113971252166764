<template>
  <div class="main-container">
    <header-tag :showAvatar="true"></header-tag>
    <div class="main">
      <a-layout style="height: 100%">
        <a-layout-sider width="200px" style="background-color: #fff">
          <a-menu mode="inline" :selectedKeys="[selectedKey]" @click="handleMenuClick">
            <a-menu-item key="/my-home">
              <home-outlined /> <span class="nav-text">我的主页</span></a-menu-item
            >
            <a-menu-item key="/my-resource"
              ><video-camera-outlined /><span class="nav-text"
                >我的资源</span
              ></a-menu-item
            >
            <a-menu-item key="/my-publish"
              ><upload-outlined /> <span class="nav-text">我的发布</span></a-menu-item
            >
            <a-menu-item key="/my-transaction">
              <account-book-outlined />
              <span class="nav-text">交易记录</span></a-menu-item
            >
            <a-menu-item key="/update-pssword"
              ><lock-outlined /> <span class="nav-text">修改密码</span></a-menu-item
            >
            <a-menu-item key="/my-info"
              ><user-outlined /> <span class="nav-text">我的信息</span></a-menu-item
            >
            <a-menu-item key="/"
              ><transaction-outlined />
              <span class="nav-text">交易大厅</span></a-menu-item
            >
          </a-menu>
        </a-layout-sider>

        <a-layout>
          <a-layout-content style="padding-left: 10px">
            <router-view></router-view>
          </a-layout-content>
        </a-layout>
      </a-layout>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watchEffect, onMounted } from "vue";
import HeaderTag from "@/components/HeaderTag.vue";
import { useRouter, useRoute } from "vue-router";
import {
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
  AccountBookOutlined,
  HomeOutlined,
  LockOutlined,
  TransactionOutlined,
} from "@ant-design/icons-vue";

const router = useRouter();
const route = useRoute();
const selectedKey = ref("");

watchEffect(() => {
  selectedKey.value = route.path;
});

onMounted(() => {
  document.title = "矩客星交易平台";
});

const handleMenuClick = ({ key }: { key: string }) => {
  if (key == "/") {
    var url = new URL(window.location.href);
    var protocol = url.protocol;
    var hostname = url.hostname;
    var port = url.port;
    if (port) {
      port = ":" + port;
    }
    const href = `${protocol}//${hostname}${port}/`;
    window.open(href);
  } else {
    router.push(key);
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  min-width: 1150px;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
  height: 100%;
}

.main {
  width: 100%;
  height: calc(100% - 80px);
}
</style>
