<template>
  <a-modal
    width="580px"
    title="创建地图"
    class="no-border-radius"
    centered
    v-model:open="internalVisible"
    @update:visible="onModalClose"
    :mask-closable="false"
    @ok="onOk"
  >
    <div class="main">
      <div class="item">
        <span class="condition-title"><span>*</span>地图名称：</span>
        <a-input
          style="width: 200px"
          show-count
          :maxlength="20"
          placeholder="请选填地图名称"
          v-model:value="resourceName"
        ></a-input>
      </div>
      <div class="item">
        <span class="condition-title"><span>*</span>适用城市：</span>
        <a-tree-select
          label-in-value
          v-model:value="cityData"
          show-search
          style="width: 200px; cursor: pointer"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择适用城市"
          allow-clear
          tree-default-expand-all
          :tree-data="treeData"
          tree-line
          tree-node-filter-prop="label"
        >
          <template #treeIcon="{ switcherCls }"
            ><down-outlined :class="switcherCls"
          /></template>
        </a-tree-select>
      </div>
      <div class="item">
        <span class="condition-title"><span>*</span>地图类型：</span>
        <a-select
          v-model:value="resourceType"
          :allow-clear="true"
          :options="mapTypeOptions"
          placeholder="请选择地图类型"
          style="width: 200px"
        ></a-select>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref,watch } from "vue";
import {  type SelectProps,TreeSelectProps } from "ant-design-vue";
import { MapTypeOptions } from "@/shared/models/select-option";
import { CidyData } from "@/shared/city-data";
import { message } from "ant-design-vue";
import { createMyResource } from "@/api/index";
import store from "@/store";

const props = defineProps({
  showModel: {
    type: Boolean,
    required: true,
    default: false
  } }
);
const emit = defineEmits(["close","ok"]);//绑定父组件事件

const treeData = ref<TreeSelectProps["treeData"]>(CidyData);

const mapTypeOptions = ref<SelectProps["options"]>(MapTypeOptions);
const resourceType = ref(null);
const resourceName = ref<string | null>(null);
const vrHref = ref<string | null>(null);

const internalVisible = ref(false);
const user = store.getters["auth/getUser"];


watch(() => props.showModel, (newValue) => {
  internalVisible.value = newValue;
});

const cityData = ref<{ label: string; value: string } | null>({
  label: user.city,
  value: `${user.cityId}`,
});

const onModalClose = (isVisible: boolean) => {
  if (!isVisible) {
    internalVisible.value = false;
    emit("close");
  }
};

const onOk =async  () => {
  if(resourceName.value == null || resourceName.value == ""){
  message.error("请输入地图名称");
  return;
}

if(cityData.value == null){
  message.error("请选择适用城市");
  return;
}

if(resourceType.value == null){
  message.error("请选择地图类型");
  return;
}
  const data = {
    resourceName: resourceName.value,
    applicableCity:cityData.value.label,
    cityId: cityData.value.value,
    resourceType: resourceType.value,
    vrHref: vrHref.value,
    author:user.nickname
  };

  const res: any = await createMyResource(data);
  if (res.success) {
      emit("ok", res.result);
      onModalClose(false);
      vrHref.value=null;
      resourceName.value = null;
      cityData.value=null;
      resourceType.value = null;
    }
}
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 250px;
  padding-top: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  margin: 5px 0 0 100px;
  height: 55px;
  line-height: 55px;

  .condition-title {
    display: inline-block;
    width: 100px;
    text-align: right;

    span {
      color: #ff0000;
      margin-right: 5px;
    }
  }
}
</style>
