<template>
  <div class="header">
    <div class="logo">
      <router-link to="/my-home">
        <img alt="logo" class="img" src="../assets/images/logo2@2x.png" />
      </router-link>
      <div v-if="showVersion" class="version">交易平台</div>
      <div class="empty" v-else></div>
    </div>
    <div v-if="showAvatar" class="avatar">
      <a-dropdown placement="bottomLeft" :arrow="{ pointAtCenter: true }">
        <img :src="avatarUrl" />
        <template #overlay>
          <a-menu>
            <a-menu-item>
              <a href="https://www.pxh360.cn/fjdt/site/help.html" target="_blank"
                >帮助文档</a
              >
            </a-menu-item>
            <a-menu-item>
              <a @click="updatePassword">修改密码</a>
            </a-menu-item>
            <a-menu-item>
              <a @click="signOut">退出系统</a>
            </a-menu-item>
          </a-menu>
        </template>
      </a-dropdown>
    </div>
    <div v-else>
      <div class="avatar">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useRouter } from "vue-router";
import { computed } from "vue";
import avater from "@/assets/images/avatar.png";
import { useStore } from "vuex";

defineProps({
  showVersion: { type: Boolean, required: false, default: true },
  showAvatar: { type: Boolean, required: false, default: false },
});

const store = useStore();
const router = useRouter();

const signOut = async () => {
  await store.dispatch("auth/logout");
  router.push({ path: "/", query: { t: Date.now() } }).then(() => {
    window.location.reload();
  });
};

const updatePassword = () => {
  router.push("/update-pssword");
};
const avatarUrl = computed(() => {
  const userAvatar = store.getters["auth/getUser"].avatar;
  if (!userAvatar) {
    return avater;
  }
  return userAvatar;
});
</script>

<style lang="scss" scoped>
.avatar {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  img {
    width: 45px;
    height: 45px;
  }
}

.header {
  width: 100%;
  height: 80px;
  background: #001627;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 256px;
  height: 80px;

  .img {
    width: 122px;
    height: 34px;
  }
}

.version {
  margin-left: 18px;
  width: 80px;
  height: 24px;
  border-radius: 3px;
  border: 1px solid #ffffff;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty {
  margin-left: 18px;
  width: 80px;
  height: 24px;
}

.logout {
  right: 20px;
}
</style>
