<template>
  <a-table
    class="ant-table-striped"
    :columns="MyTransactionColumns"
    :data-source="dataSource"
    :pagination="pagination"
    @change="handlePaginationChange"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.key === 'resourceName'">
        {{ record.resourceName }}
      </template>
      <template v-else-if="column.key === 'transactionType'">
        <span>
          <a-tag :color="transactionTypeData[record.transactionType][0]">{{
            transactionTypeData[record.transactionType][1]
          }}</a-tag>
        </span>
      </template>
      <template v-else-if="column.key === 'resourceType'">
        <span>
          <a-tag :color="resourceTypeData[record.resourceType][0]">{{
            resourceTypeData[record.resourceType][1]
          }}</a-tag>
        </span>
      </template>
    </template>
  </a-table>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { getTransactionRecordPageList } from "@/api/index";
import { MyTransactionColumns } from "@/shared/models/table-columns";
import { TransactionTypeData, ResourceTypeData } from "@/shared/models/tag-data";
import dayjs from "dayjs";

const props = defineProps({
  number: {
    type: String,
    required: true,
  },
});

const transactionTypeData = ref(TransactionTypeData);
const resourceTypeData = ref(ResourceTypeData);

const dataSource = ref<any[]>([]);
const resourceType = ref(null);
const pagination = ref({
  current: 1,
  pageSize: 8,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: false,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});

onMounted(() => {
  watch(
    () => props.number,
    async (newValue) => {
      if (newValue) {
        query(newValue);
      }
    },
    { immediate: true }
  );
});

const handlePaginationChange = async (page: any) => {
  pagination.value.current = page.current;
  pagination.value.pageSize = page.pageSize;
  await query(props.number);
};

const query = async (number: string) => {
  if ("" === number) return;
  const data = {
    resourceType: resourceType.value,
    number,
  };
  const res: any = await getTransactionRecordPageList(
    data,
    pagination.value.current,
    pagination.value.pageSize
  );
  dataSource.value = res.result.items.map((item: any) => {
    item.transactionTime = dayjs(item.transactionTime).format("YYYY-MM-DD HH:mm");
    return item;
  });
  pagination.value.total = res.result.totalCount;
};
</script>

<style lang="scss" scoped></style>
