<template>
  <div class="main">
    <div class="search-condition">
      <div style="width: 100%">
        <span class="condition-title">资源类型：</span>
        <a-select
          v-model:value="resourceType"
          :allow-clear="true"
          :options="resourceTypeOptions"
          @change="selectChange"
          placeholder="请选择资源类型"
          style="width: 200px"
        ></a-select>
        &nbsp;&nbsp;&nbsp;
        <span class="condition-title">销售状态：</span>
        <a-select
          v-model:value="saleStatus"
          :allow-clear="true"
          :options="saleStatusOptions"
          @change="selectChange"
          placeholder="请选择销售状态"
          style="width: 200px"
        ></a-select>
        &nbsp;&nbsp;&nbsp;
        <span class="condition-title">资源编码：</span>
        <a-input
          style="width: 200px"
          placeholder="请选填写源编码"
          :allowClear="true"
          v-model:value="sourceNumber"
        ></a-input>
        <a-button type="primary" @click="query">查询</a-button>
      </div>
    </div>
    <div class="sale-info">
      <span
        >累计交易金额：<b class="b1">￥{{ totalAmount }}</b></span
      >
    </div>

    <div style="padding: 0 10px">
      <a-table
        :columns="MyPublishColumns"
        :data-source="dataSource"
        :pagination="pagination"
        @change="handlePaginationChange"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'publishTitle'">
            <a @click="viewResource(record.number)">{{ record[column.key] }}</a>
          </template>
          <template v-if="column.key === 'count' || column.key === 'totalAmount'">
            <a title="点击查询交易记录" @click="showTransaction(record.number)">{{
              record[column.key]
            }}</a>
          </template>
          <template v-else-if="column.key === 'resourceType'">
            <span>
              <a-tag :color="resourceTypeData[record.resourceType][0]">{{
                resourceTypeData[record.resourceType][1]
              }}</a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'saleStatus'">
            <span>
              <a-tag :color="saleStatusData[record.saleStatus][0]">{{
                saleStatusData[record.saleStatus][1]
              }}</a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'usableMonths'">
            <span>
              <a-tag :color="usableMonths[record.usableMonths][0]">{{
                usableMonths[record.usableMonths][1]
              }}</a-tag>
            </span>
          </template>
          <template v-else-if="column.key === 'action'">
            <div class="action">
              <a-popconfirm
                :title="
                  record.saleStatus == 1 ? '确实下架该资源吗？' : '确实上架该资源吗？'
                "
                placement="left"
                :ok-text="record.saleStatus == 1 ? '下架' : '上架'"
                @confirm="confirm(record)"
              >
                <template #icon><question-circle-outlined style="color: red" /></template>
                <a v-if="record.saleStatus == 1">下架</a> <a v-else>上架</a>
              </a-popconfirm>
            </div>
          </template>
        </template>
      </a-table>
    </div>

    <a-modal
      width="1024px"
      min-width="1024px"
      class="no-border-radius"
      centered
      :footer="null"
      v-model:open="showTransModal"
      :closable="false"
      ><number-transaction :number="currentNumber"></number-transaction
    ></a-modal>

    <a-modal
      class="no-border-radius"
      width="100%"
      height="100%"
      :style="{
        width: '100%',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showLoadMap"
    >
      <div style="height: 92vh">
        <iframe
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          :key="iframeKey"
          scrolling="no"
          :src="url"
        ></iframe>
      </div>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import type { SelectProps } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import store from "@/store";
import {
  getMyPublishPageList,
  getMyResourceByNumber,
  setResourceInfoSaleStatus,
} from "@/api/index";
import {
  ResourceTypeOptions,
  SaleStatusTypeOptions,
} from "@/shared/models/select-option";
import { MyPublishColumns } from "@/shared/models/table-columns";
import NumberTransaction from "@/views/console/my-publish/number-transaction/index.vue";
import { getResourceEditOrViewUrl } from "@/shared/utils";

const dataSource = ref<any[]>([]);
const resourceTypeOptions = ref<SelectProps["options"]>(ResourceTypeOptions);
const saleStatusOptions = ref<SelectProps["options"]>(SaleStatusTypeOptions);
import dayjs from "dayjs";
import { ResourceTypeData, UsableMonths, SaleStatus } from "@/shared/models/tag-data";

const resourceTypeData = ref(ResourceTypeData);
const usableMonths = ref(UsableMonths);
const saleStatusData = ref(SaleStatus);

const resourceType = ref(null);
const saleStatus = ref(null);
const sourceNumber = ref(null);
const totalAmount = ref(0);
const showLoadMap = ref(false);
const url = ref("");
const iframeKey = ref(0);

const showTransModal = ref(false);
const currentNumber = ref<string>("");

const pagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: true,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});

const confirm = (record: any) => {
  setSaleStatus(record);
};

const setSaleStatus = async (record: any) => {
  await setResourceInfoSaleStatus(record.number, record.saleStatus == 1 ? 2 : 1);
  query();
};

const showTransaction = async (number: string) => {
  currentNumber.value = number;
  showTransModal.value = true;
};

const handlePaginationChange = (page: any) => {
  pagination.value.current = page.current;
  pagination.value.pageSize = page.pageSize;
  query();
};

const query = () => {
  getMyPublishList({
    resourceType: resourceType.value,
    saleStatus: saleStatus.value,
    number: sourceNumber.value,
  });
};

/**
 *
 * 浏览资源
 */
const viewResource = async (number: string) => {
  const res: any = await getMyResourceByNumber(number);
  if (res.success) {
    //浏览发布时的副本
    const record = Object.assign({}, res.result);
    record.originalMapId = res.result.publishMapId;
    url.value = getResourceEditOrViewUrl(record, store.getters["auth/getToken"], "view");
    showLoadMap.value = true;
    iframeKey.value++;
  }
};

const getMyPublishList = async (data: any) => {
  const res: any = await getMyPublishPageList(
    data,
    pagination.value.current,
    pagination.value.pageSize
  );
  if (res.result) {
    dataSource.value = res.result.items.map((item: any) => {
      item.publishTime = dayjs(item.publishTime).format("YYYY-MM-DD HH:mm");
      return item;
    });
    totalAmount.value = res.result.totalAmount;
    pagination.value.total = res.result.totalCount;
  }
};

const selectChange = () => {
  pagination.value.current = 1;
  query();
};

query();
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-width: 980px;
  height: 100%;
  background-color: #fff;
}
.sale-info {
  width: 100%;
  height: 40px;
  padding-left: 20px;

  .b1 {
    color: #39ae00;
    font-weight: normal;
  }
}
.search-condition {
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  position: relative;
  top: 30px;
  height: 100px;
  width: 100%;

  button {
    margin-left: 10px;
  }
}
</style>
