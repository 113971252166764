
import type { SelectProps } from "ant-design-vue";

type SelectOptionType = SelectProps["options"];

/**
 * 资源类型
 */
export const ResourceTypeOptions: SelectOptionType =
  [
    {
      value: "1",
      label: "城区地图",
    },

    {
      value: "2",
      label: "商圈地图",
    },
    {
      value: "3",
      label: "楼盘地图",
    },
    {
      value: "4",
      label: "实景VR",
    }
  ];


/**
 * 地图类型
 */
export const MapTypeOptions: SelectOptionType =
  [
    {
      value: "1",
      label: "城区地图",
    },
    {
      value: "2",
      label: "商圈地图",
    },
    {
      value: "3",
      label: "楼盘地图",
    },
  ];

/**
* VR类型
*/
export const VrTypeOptions: SelectOptionType =
  [
    {
      value: "4",
      label: "实景VR",
    },
  ];


/**
 * 审核状态
 */
export const ReviewStatusOptions: SelectOptionType =
  [
    {
      value: "0",
      label: "待审",
    },
    {
      value: "1",
      label: "通过",
    },
    {
      value: "2",
      label: "拒绝",
    },
  ];


/**
 * 来源类型
 */
export const SourceTypeOptions: SelectOptionType = [
  {
    value: "1",
    label: "购入",
  },
  {
    value: "2",
    label: "自制",
  },
  {
    value: "3",
    label: "内置",
  },
];


/**
 * 交易类型
 */
export const TransactionTypeOptions: SelectOptionType = [
  {
    value: "1",
    label: "购入",
  },
  {
    value: "2",
    label: "出售",
  },
];


/**
 * 销售状态
 */
export const SaleStatusTypeOptions: SelectOptionType = [
  {
    value: "1",
    label: "销售中",
  },
  {
    value: "2",
    label: "已下架",
  },
];
