<template>
  <div class="map-sub-container">
    <div class="text-info" :title="data.title">{{ data.title }}</div>
    <div class="map-base">
      <div class="map-info">
        <div class="map-type">适用城市：{{ data.region }}</div>
        <div class="map-type">资源类型：{{ data.type }}</div>
        <div class="map-type">
          有效时间：{{
            data.usableMonths == 0
              ? "长期"
              : data.usableMonths == 12
              ? "1年"
              : data.usableMonths + "个月"
          }}
        </div>
      </div>
      <div
        style="width: 153px; display: flex; flex-direction: column"
        v-if="data.price > 0"
      >
        <div class="price">
          <span class="price-color">{{ data.price }}</span
          >元/份
        </div>
        <div class="sell-price">出售价格</div>
      </div>
      <div v-else-if="data.price == 0" class="map-price">免费</div>
    </div>
    <div class="map-author">
      <span class="line" title="发布人"
        ><img :src="data.avatarUrl ?? avatarUrl" />{{ data.author ?? "匿名" }}</span
      >
      <span class="line" title="发布时间"
        ><img src="@/assets/images/@map-date.png" />{{ data.date }}</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { PropType, ref } from "vue";
import avater from "@/assets/images/avatar.png";
import type ResourceCard from "@/shared/models/resource-card";

defineProps({
  data: {
    type: Object as PropType<ResourceCard>,
    required: true,
  },
});

const avatarUrl = ref(avater);
</script>

<style lang="scss" scoped>
.map-sub-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.text-info {
  margin: 10px 0;
  width: 100%;
  height: 22px;
  font-weight: 500;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.map-base {
  height: 65px;
  line-height: 65px;
  display: flex;
  justify-content: flex-start;
}

.map-info {
  width: 185px;
}

.map-type {
  flex: 0 0 auto;
  height: 23px;
  line-height: 23px;
  font-weight: 400;
  font-size: 14px;
  color: #595959;
  text-align: left;
  font-style: normal;
}

.map-price {
  width: 153px;
  height: 65px;
  line-height: 65px !important;
  font-size: 22px;
  color: #f5222d;
  line-height: 30px;
  text-align: left;
  font-style: normal;
}

.map-author {
  width: 100%;
  line-height: 40px;
  height: 40px;
  font-size: 14px;
  color: #595959;
}

.line {
  display: inline-block;
  width: 100px;
  height: 40px;
  line-height: 40px !important;
  font-size: 14px;
  color: #8c8c8c;
  line-height: 20px;
  text-align: left;
  font-style: normal;
  position: relative;
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  img {
    width: 17px;
    height: 17px;
    margin-right: 5px;
    margin-bottom: 3px;
    border-radius: 50%;
  }
}

.sell-price {
  width: 48px;
  height: 16px;
  font-weight: 400;
  font-size: 12px;
  color: #f5222d;
  line-height: 16px;
  text-align: left;
  font-style: normal;

  background: #fff2ef;
  border-radius: 2px;
}

.price {
  font-weight: 400;
  font-size: 12px;
  color: #8c8c8c;
  text-align: left;
  font-style: normal;
  height: 46px;
  line-height: 46px;
}

.price-color {
  font-weight: 500;
  font-size: 25px;
  color: #f5222d;
  line-height: 34px;
  font-style: normal;
  margin-right: 2px;
}
</style>
