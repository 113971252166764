<template>
  <a-modal
    width="580px"
    title="创建VR"
    class="no-border-radius"
    centered
    v-model:open="internalVisible"
    @update:visible="onModalClose"
    :mask-closable="false"
    @ok="onOk"
  >
    <div class="main">
      <div class="item">
        <span class="condition-title"
          ><span>*</span><em style="margin-right: 11px">VR</em>名称：</span
        >
        <a-input
          style="width: 200px"
          show-count
          :maxlength="20"
          placeholder="请选填VR名称"
          v-model:value="resourceName"
        ></a-input>
      </div>
      <div class="item">
        <span class="condition-title"><span>*</span>适用城市：</span>
        <a-tree-select
          label-in-value
          v-model:value="cityData"
          show-search
          tree-line
          style="width: 200px; cursor: pointer"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          placeholder="请选择适用城市"
          allow-clear
          tree-default-expand-all
          :tree-data="treeData"
          tree-node-filter-prop="label"
        >
        </a-tree-select>
      </div>
      <div class="item">
        <span class="condition-title"
          ><span>*</span><em style="margin-right: 11px">VR</em>链接：</span
        >
        <a-input
          style="width: 200px"
          show-count
          :maxlength="800"
          placeholder="请选填VR链接"
          v-model:value="vrHref"
        ></a-input>
      </div>
    </div>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref, watch } from "vue";
import { TreeSelectProps } from "ant-design-vue";
import { CidyData } from "@/shared/city-data";
import { message } from "ant-design-vue";
import { createMyResource } from "@/api/index";
import store from "@/store";

const props = defineProps({
  showModel: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const emit = defineEmits(["close", "ok"]); //绑定父组件事件
const treeData = ref<TreeSelectProps["treeData"]>(CidyData);

const showVrHref = ref(false);

const resourceType = ref("4");
const resourceName = ref<string | null>(null);
const vrHref = ref<string | null>(null);

const internalVisible = ref(false);
const user = store.getters["auth/getUser"];

watch(
  () => props.showModel,
  (newValue) => {
    internalVisible.value = newValue;
  }
);

const cityData = ref<{ label: string; value: string } | null>({
  label: user.city,
  value: `${user.cityId}`,
});

const onModalClose = (isVisible: boolean) => {
  if (!isVisible) {
    internalVisible.value = false;
    emit("close");
  }
};

const onOk = async () => {
  if (resourceName.value == null || resourceName.value == "") {
    message.error("请输入VR名称");
    return;
  }

  if (cityData.value == null) {
    message.error("请选择适用城市");
    return;
  }

  if (showVrHref.value && vrHref.value == null) {
    message.error("请输入VR链接");
    return;
  }

  if (!isValidURL(vrHref.value)) {
    message.error("请检查VR链接");
    return;
  }

  const data = {
    resourceName: resourceName.value,
    applicableCity: cityData.value.label,
    cityId: cityData.value.value,
    resourceType: resourceType.value,
    vrHref: vrHref.value,
    author: user.nickname,
  };

  const res: any = await createMyResource(data);
  if (res.success) {
    message.success("VR创建成功");
    emit("ok", data);
    onModalClose(false);
    vrHref.value = null;
    resourceName.value = null;
    cityData.value = null;
  }
};

const isValidURL = (url: string | null): boolean => {
  if (null == url) return false;
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 250px;
  padding-top: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  margin: 5px 0 0 100px;
  height: 55px;
  line-height: 55px;

  .condition-title {
    display: inline-block;
    width: 100px;
    text-align: right;

    span {
      color: #ff0000;
      margin-right: 5px;
    }
  }
}
</style>
