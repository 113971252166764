interface TreeNode {
    label: string;
    value: string;
    children?: TreeNode[];
}

export function findNodeByValue(nodes: TreeNode[], targetValue: string): TreeNode | null {
    for (const node of nodes) {       
        if (node.value == targetValue) {
            return node;
        }
        if (node.children) {
            const found = findNodeByValue(node.children, targetValue);
            if (found != null) {
                return found;
            }
        }
    }
    return null;
}