
type TagDataType = { [key: number]: [string, string] };

export const ResourceTypeData: TagDataType = {
  1: ["#108ee9", "城区地图"],
  2: ["#E534BC", "商圈地图"],
  3: ["#3b5999", "楼盘地图"],
  4: ["#87d068", "实景VR"],
};


export const ReviewStatusData: TagDataType = {
  0: ["#f50000", "待审"],
  1: ["#2db7f5", "通过"],
  2: ["#87d068", "拒绝"],
};


export const SourceTypeData: TagDataType = {
  1: ["#108ee9", "购入"],
  2: ["#87d068", "自制"],
  3: ["#3b5999", "内置"],
};


export const TransactionTypeData: TagDataType = {
  1: ["#108ee9", "购入"],
  2: ["#87d068", "出售"],
};
export const ResourceStatusData: TagDataType = {
  0: ["#FAC11C", "未发布"],
  1: ["#2db7f5", "销售中"],
  2: ["#f50000", "已下架"],
};


export const SaleStatus: TagDataType = {
  1: ["#2db7f5", "销售中"],
  2: ["#f50000", "已下架"],
};   


export const UsableMonths: TagDataType = {
  0: ["#2db7f5", "长期"],
  1: ["#108ee9", "1个月"],
  3: ["#87d068", "3个月"],
  12: ["#E534BC", "1年"],
};