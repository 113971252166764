<template>
  <ConfigProvider :locale="locale">
    <div id="app">
      <router-view v-slot="{ Component }">
        <keep-alive>
          <component :is="Component" v-if="$route.meta.keepAlive" />
        </keep-alive>
        <component :is="Component" v-if="!$route.meta.keepAlive" />
      </router-view>
    </div>
  </ConfigProvider>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
const locale = ref(zhCN);
</script>
