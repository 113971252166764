<template>
  <div class="steps-content">
    <div class="module-content1">
      <div style="width: 450px; height: 420px">
        <iframe
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          scrolling="no"
          :key="iframeKey"
          :src="url"
        ></iframe>
      </div>
      <div style="width: 450px; height: 420px; margin-left: 10px; text-align: left">
        <div class="publish-content">
          <div style="width: 40%"><b>资源类型：</b>{{ type }}</div>
          <div style="width: 60%"><b>适用城市：</b>{{ city }}</div>
        </div>
        <div class="publish-content">
          <b>发布标题：</b>
          <a-input
            show-count
            :maxlength="20"
            style="width: 285px; height: 32px"
            ref="titleInput"
            v-model:value="title"
            placeholder="请输入标题"
          />
        </div>
        <div class="publish-content">
          <b>出售价格：</b>
          <a-input-number
            style="width: 100px; height: 32px"
            ref="priceInput"
            :controls="false"
            @keydown="handleKeyDown"
            :min="0"
            :step="0.01"
            v-model:value="price"
            placeholder="请输入价格"
          />&nbsp;&nbsp;元/份<span style="color: red; margin-left: 20px"
            >注：0 或空代表免费</span
          >
        </div>
        <div class="publish-content">
          <b>有效时间：</b>
          <a-radio-group v-model:value="months">
            <a-radio :value="0">长期</a-radio>
            <a-radio :value="12">一年</a-radio>
            <a-radio :value="3">三个月</a-radio>
            <a-radio :value="1">一个月</a-radio>
          </a-radio-group>
        </div>
        <div class="publish-content">
          <b>资源封面：</b>
          <a-upload
            :show-upload-list="true"
            :file-list="fileList"
            :before-upload="beforeUpload"
            @remove="handleRemove"
          >
            <a-button>
              <upload-outlined></upload-outlined>
              上传封面(350*220)
            </a-button>
          </a-upload>
        </div>
        <div class="mainze">
          <div class="wenzi">
            <span>注意事项：</span
            ><br />1、请确保您拥有当前发布资源的全部完整知识产权。若因知识产权造成的纠纷<em
              style="padding-left: 20px; font-style: normal"
              >和后果与矩客星平台无关；</em
            ><br />
            2、交易一旦发布，将展示在矩客星交易大厅供浏览和出售，发布后无法修改价<em
              style="padding-left: 20px; font-style: normal"
              >格等信息；</em
            ><br />
            3、在推广期内矩客星平台不参与交易分成；<br />
            4、交易双方不得私下交易，若私下交易权益受损与矩客星平台无关；<br />
            5、若交易过程中有任何疑问，可加矩客星平台客服微信”aidesoft“沟通反馈。
          </div>
        </div>
      </div>
    </div>
    <div class="steps-action">
      <a-button style="margin-right: 10px" @click="close"> 取消 </a-button>
      <a-button type="primary" @click="publish"> 发布 </a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, watch } from "vue";
import { getResourceEditOrViewUrl } from "@/shared/utils";
import { createMyPublish, uploadImage } from "@/api/index";
import { ResourceTypeData } from "@/shared/models/tag-data";
import { Modal, message } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import store from "@/store";
import type { UploadProps } from "ant-design-vue";
const user = store.getters["auth/getUser"];

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
});

const months = ref(12);
const titleInput = ref<HTMLInputElement | null>(null);
const priceInput = ref<HTMLInputElement | null>(null);
const title = ref<string>("");
const price = ref<number>(0);
const city = ref("");
const type = ref("");

const record = ref<any | null>(props.record);

onMounted(() => {
  watch(
    () => props.record,
    async (newValue) => {
      if (newValue) {
        record.value = newValue;
        loadResource(newValue);
      }
    },
    { immediate: true }
  );
});

const emit = defineEmits(["close", "publish"]);

const iframeKey = ref(0);
const url = ref("");

const close = () => {
  emit("close");
};

const fileList = ref<UploadProps["fileList"]>([]);

const handleRemove: UploadProps["onRemove"] = () => {
  fileList.value = [];
};

const beforeUpload: UploadProps["beforeUpload"] = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("请上传JPG/PNG文件，且大小不能超过200KB");
    return false;
  }

  if (file.size > 200 * 1024) {
    message.error("图片大小不能超过200KB");
    return false;
  }

  fileList.value = [file];
  return false;
};

const publish = () => {
  if (title.value == "") {
    message.error("标题不能为空，请输入标题");
    titleInput.value?.focus();
    return;
  }
  if (price.value < 0) {
    message.error("价格不能小于0，请输入价格");
    priceInput.value?.focus();
    return;
  }
  showPromiseConfirm();
};

const showPromiseConfirm = () => {
  Modal.confirm({
    title: "确认发布",
    icon: createVNode(ExclamationCircleOutlined),
    content:
      "发布的资源将会在交易大厅公开展示，供用户查看和交易。请确保标题描述清楚，以便让用户快速理解您的意图。",
    async onOk() {
      //如果有封面的话，要先上传封面
      let imageUrl = "";
      if (fileList.value && fileList.value.length > 0) {
        const formData = new FormData();
        formData.append("file", fileList.value[0] as any);
        const res: any = await uploadImage(formData);
        if (res.success) {
          imageUrl = res.result.url;
        } else {
          message.error("封面上传失败，请重试");
          return;
        }
      }

      const data = {
        publishTitle: title.value,
        price: price.value,
        imageUrl: imageUrl,
        avatarUrl: user.avatar,
        applicableCity: record.value.applicableCity,
        cityId: record.value.cityId,
        resourceName: record.value.name,
        resourceType: record.value.resourceType,
        originalMapId: record.value.originalMapId,
        number: record.value.number,
        usableMonths: months.value,
      };

      const res: any = await createMyPublish(data);
      if (res.success) {
        if (data.number == 0) {
          message.success("资源已转入我的资源列表，发布成功");
        } else {
          message.success("资源已发布成功");
        }
        price.value = 0;
        record.value = null;
        emit("publish");

        return await new Promise((resolve) => {
          resolve(res);
        });
      }
    },
  });
};

const handleKeyDown = (event: any) => {
  const allowedKeys = [
    "Backspace",
    "ArrowLeft",
    "ArrowRight",
    "Delete",
    "Tab",
    "Home",
    "End",
  ];
  const key = event.key;

  if (!/\d/.test(key) && key !== "." && !allowedKeys.includes(key)) {
    event.preventDefault();
  }

  // 确保只有一个小数点
  if (key === "." && event.target.value.includes(".")) {
    event.preventDefault();
  }
};

const loadResource = async (record: any) => {
  console.log(record);
  loadResourceToView(record);
  city.value = record.applicableCity;
  type.value = ResourceTypeData[record.resourceType][1];
};

const loadResourceToView = (record: any) => {
  url.value = getResourceEditOrViewUrl(record, store.getters["auth/getToken"], "view");
  iframeKey.value++;
};
</script>

<style lang="scss" scoped>
.mainze {
  width: 520px;
  height: 215px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;

  .wenzi {
    width: 520px;
    height: 210px;
    font-size: 14px;
    color: #595959;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    margin: 15px;
    padding-top: 8px;
    span {
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

.publish-content {
  height: 41px;
  line-height: 41px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.steps-content {
  min-height: 530px;
  text-align: center;
  width: 1024px;
  height: 530px;
  padding-top: 30px;
}

.steps-action {
  width: 980px;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
}

.module-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
  min-width: 900px;
}

.module-content1 {
  display: flex;
  align-items: center;
}

:deep(.ant-upload-list) {
  float: right;
  margin-left: 10px;
}

:deep(.ant-upload-list-item-name) {
  max-width: 180px;
}
</style>
