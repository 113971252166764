import axios from "axios";
import { networkConfig } from "../config/networkConfig";
import { message } from "ant-design-vue";
import store from "@/store";


const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: networkConfig.serverUrl,
  // 设置超时
  timeout: networkConfig.requestTimeout,
  // 确保每次请求都会携带 Cookie
  withCredentials: false,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded";
    }
    const token = store.getters["auth/getToken"] ?? "";
    config.headers["Authorization"] = "Bearer " + token;

    const user = store.getters["auth/getUser"];
    const nickname = user ? user.nickname : "";
    config.headers["X-Nick-Name"] = encodeURIComponent(nickname);

    if (config.url) {
      config.url += (config.url.indexOf('?') === -1 ? '?' : '&') + "t=" + Date.now();
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    if (res.status == 200) {
      if (res.data) {
        const { result } = res.data;
        if (result && result.exceptionType) {
          return Promise.reject({ response: { data: result.exceptionMessage } });
        }
        return res.data;
      }
    }
    return res;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export function httpRequest(config: any) {
  return new Promise((resolve) => {
    service(config).then((res: any) => {
      resolve(res);
    }).catch(async (error) => {
      console.error(error);
      const { response } = error;
      if (response) {
        if (response.status == 401) {
          await store.dispatch("auth/logout");
          resolve({ scuccess: false, error: { message: "系统要求登录" } });
        }
        else {
          let errMessage = '网络错误，请重试';
          if (response.data) {
            if (typeof response.data === 'string') {
              errMessage = response.data;

            } else if (response.data.error && response.data.error.message) {
              errMessage = response.data.error.message;
            }
          }
          message.error(errMessage);
          resolve({ scuccess: false, error: { message: errMessage } });
        }
      }
      resolve({ scuccess: false, error: { message: "" } });
    });
  });
}

export default service;
