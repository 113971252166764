<template>
  <div class="main">
    <div class="left">
      <div class="item">
        <span class="title">登录用户：</span>
        <span class="value">{{ user.uid }}</span>
      </div>
      <div class="item">
        <span class="title">到期日期：</span>
        <span class="value">{{
          dayjs(user.expiredTime * 1000).format("YYYY-MM-DD")
        }}</span>
      </div>
      <div class="item">
        <span class="title">公司名/姓名：</span>
        <span class="value">{{ user.name }}</span>
      </div>
      <div class="item">
        <span class="title">企业信用代码/居民身份证号：</span>
        <span class="value">暂无</span>
      </div>
      <div class="item">
        <span class="title">昵称：</span>
        <a-input
          style="width: 200px"
          placeholder="请选填写昵称"
          v-model:value="nickname"
        ></a-input>
      </div>
      <div class="item">
        <span class="title">介绍：</span>
        <a-textarea
          style="width: 200px"
          placeholder="请选填写介绍"
          allow-clear
        ></a-textarea>
      </div>
      <div class="item">
        <a-button
          type="primary"
          style="margin-left: 201px; margin-top: 30px"
          @click="saveHandler"
          :loading="handlerloading"
          >保存</a-button
        >
      </div>
    </div>
    <div class="right">
      <a-upload
        v-model:file-list="fileList"
        name="file"
        :action="uploadUrl"
        :headers="headers"
        @change="handleChange"
        :before-upload="beforeUpload"
        :show-upload-list="false"
      >
        <img :src="imageUrl" />
        <loading-outlined v-if="loading" class="avatar-login"></loading-outlined>
      </a-upload>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import dayjs from "dayjs";
import store from "@/store";
import { message } from "ant-design-vue";
import { LoadingOutlined } from "@ant-design/icons-vue";
import type { UploadChangeParam, UploadFile } from "ant-design-vue";
import { updateInfo } from "@/api/index";
import { networkConfig } from "@/config/networkConfig";
import avatar from "@/assets/images/avatar.png";

const user = store.getters["auth/getUser"];
const loading = ref(false);
const nickname = ref(user.nickname);
const imageUrl = ref(avatar);
const handlerloading = ref(false);
const uploadUrl = ref(networkConfig.serverUrl + "/account/uploadImage");

const handleChange = async (info: UploadChangeParam) => {
  if (info.file.status) {
    loading.value = true;
  }

  if (info.file.status === "done") {
    loading.value = false;
    if (info.file.response.success) {
      imageUrl.value = info.file.response.result.url;
    } else {
      message.error(
        `${info.file.name} 上传失败，原因：[${info.file.response.error.message}]`
      );
    }
  } else if (info.file.status === "error") {
    loading.value = false;
    message.error(`${info.file.name} 上传失败，请重试`);
  }
};

const saveHandler = async () => {
  if (!nickname.value) {
    message.error("请填写用户昵称");
    return;
  }
  handlerloading.value = true;
  const res: any = await updateInfo({
    nickname: nickname.value,
    avatar: imageUrl.value,
  });
  handlerloading.value = false;
  if (res.success) {
    //更新成功，同步显示其它用户头像
    message.success("用户信息保存成功");
    user.avatar = imageUrl.value;
    user.nickname = nickname.value;
    await store.dispatch("auth/setUser", { user });
  } else {
    message.error(res.error.message);
  }
};
const fileList = ref([]);

const headers = {
  Authorization: "Bearer " + store.getters["auth/getToken"],
};

const beforeUpload = (file: UploadFile) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("请上传JPG/PNG文件，且大小不能超过2MB");
  }
  const isLt2M = file.size ? file.size / 1024 / 1024 < 2 : false;
  if (!isLt2M) {
    message.error("图片大小不能超过2MB");
  }
  return isJpgOrPng && isLt2M;
};

const init = () => {
  if (user.avatar) {
    imageUrl.value = user.avatar;
  }
};

init();
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
}
.left {
  padding-top: 20px;
  display: block;
  width: 580px;

  .item {
    margin: 5px 0 0 80px;
    height: 55px;
    line-height: 55px;

    .title {
      display: inline-block;
      width: 250px;
      text-align: right;
    }
  }
}

.right {
  display: block;
  width: 250px;
  height: 100%;

  img {
    margin-top: 80px;
    margin-left: 30px;
    width: 280px;
    height: 280px;
  }
}

.avatar-login {
  font-size: 30px;
  color: #0000ff;
  position: relative;
  top: -155px;
  left: 160px;
}
</style>
