import { httpRequest } from "../http/request";

const controllerName = 'transactionRecord'

/**
 * 获取交易列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getTransactionRecordPageList
    (data: any, pageIndex: number, pageSize: number) {
    return httpRequest({
        url: `/${controllerName}/list?pageIndex=${pageIndex}&pageSize=${pageSize}`,
        method: "post",
        data: {
            ...data,
            sort: 'Number'
        }
    });
}


/**
 * 购买资源
 */
export function buy(number: string) {
    return httpRequest({
        url: `/${controllerName}/buy`,
        method: "post",
        data: {
            number
        }
    });
}
