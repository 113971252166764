import { updatePassword } from '../../api/account';
<template>
  <div class="main">
    <div class="item">
      <span class="condition-title"><span>*</span>旧密码：</span>
      <a-input-password
        show-count
        :maxlength="20"
        style="width: 200px"
        placeholder="请选填写旧密码"
        v-model:value="password"
      ></a-input-password
      ><span style="margin-left: 10px">初始密码：888888</span>
    </div>
    <div class="item">
      <span class="condition-title"><span>*</span>新密码：</span>
      <a-input-password
        show-count
        :maxlength="20"
        style="width: 200px"
        placeholder="请选填写新密码"
        v-model:value="newPassword"
      ></a-input-password>
    </div>
    <div class="item">
      <span class="condition-title"><span>*</span>确认新密码：</span>
      <a-input-password
        show-count
        :maxlength="20"
        style="width: 200px"
        placeholder="请选填写确认新密码"
        v-model:value="confirmPassword"
      ></a-input-password>
    </div>
    <div class="item">
      <a-button
        type="primary"
        @click="updatePasswordHandler"
        style="margin-left: 100px"
        :loading="loading"
        >修改</a-button
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { updatePassword } from "@/api/index";
import { ref } from "vue";
import { message } from "ant-design-vue";
const password = ref("");
const newPassword = ref("");
const confirmPassword = ref("");
import { useStore } from "vuex";

const loading = ref(false);
const store = useStore();
const updatePasswordHandler = async () => {
  if (password.value === "") {
    message.error("请正确输入旧密码");
    return;
  }

  if (newPassword.value === "") {
    message.error("请正确输入新密码");
    return;
  }

  if (newPassword.value.length < 6) {
    message.error("新密码必须大于6位");
    return;
  }

  if (confirmPassword.value === "") {
    message.error("请正确输入确认密码");
    return;
  }

  if (password.value == newPassword.value) {
    message.error("新密码不能与旧密码相同");
    return;
  }

  if (newPassword.value !== confirmPassword.value) {
    message.error("两次密码输入不一致");
    return;
  }

  loading.value = true;
  const res: any = await updatePassword(
    password.value,
    newPassword.value,
    confirmPassword.value
  );
  loading.value = false;
  if (!res.success) {
    message.error(res.error.message);
    return;
  }
  message.success("密码修改成功，请重新登录");
  await store.dispatch("auth/logout");
  setTimeout(() => {
    password.value = newPassword.value = confirmPassword.value = "";
  }, 500);
};
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100%;
  padding-top: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  margin: 5px 0 0 100px;
  height: 55px;
  line-height: 55px;

  .condition-title {
    display: inline-block;
    width: 100px;
    text-align: right;

    span {
      color: #ff0000;
      margin-right: 5px;
    }
  }
}
</style>
