<template>
  <div class="main">
    <div style="padding: 0 10px">
      <a-tabs v-model:activeKey="activeKey" @change="tabIndex">
        <a-tab-pane key="1" tab="我的资源">
          <div class="search-condition">
            <div style="width: 100%">
              <span class="condition-title">资源类型：</span>
              <a-select
                v-model:value="myResourceType"
                :allow-clear="true"
                :options="resourceTypeOptions"
                @change="mySelectChange"
                placeholder="请选择资源类型"
                style="width: 200px"
              ></a-select>
              &nbsp;&nbsp;&nbsp;
              <span class="condition-title">资源来源：</span>
              <a-select
                v-model:value="sourceType"
                :allow-clear="true"
                :options="sourceTypeOptions"
                @change="mySelectChange"
                placeholder="请选择资源来源"
                style="width: 200px"
              ></a-select>
              &nbsp;&nbsp;&nbsp;
              <span class="condition-title">资源编码：</span>
              <a-input
                style="width: 200px"
                :allowClear="true"
                placeholder="请填写源编码"
                v-model:value="sourceNumber"
              ></a-input>
              <a-button type="primary" @click="myQuery">查询</a-button>
            </div>
          </div>
          <div class="sale-info">
            <a-button type="primary" @click="createMap">创建地图</a-button>
            <a-button type="primary" @click="createVR">创建VR</a-button>
          </div>
          <a-table
            :columns="MyResourceColumns"
            :data-source="myDataSource"
            :pagination="myPagination"
            @change="myHandlePaginationChange"
          >
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'resourceName'">
                <a @click="myViewResource(record)">{{ record.resourceName }}</a>
              </template>
              <template v-else-if="column.key === 'sourceType'">
                <span>
                  <a-tag :color="sourceTypeData[record.sourceType][0]">{{
                    sourceTypeData[record.sourceType][1]
                  }}</a-tag>
                </span>
              </template>
              <template v-else-if="column.key === 'resourceType'">
                <span>
                  <a-tag :color="resourceTypeData[record.resourceType][0]">{{
                    resourceTypeData[record.resourceType][1]
                  }}</a-tag>
                </span>
              </template>
              <template v-else-if="column.key === 'status'">
                <span>
                  <a-tag :color="resourceStatusData[record.status][0]">{{
                    resourceStatusData[record.status][1]
                  }}</a-tag>
                </span>
              </template>
              <template v-else-if="column.key === 'action'">
                <div class="action">
                  <template v-if="record.resourceType != 4">
                    <a @click="designMap(record)">设计地图</a>
                  </template>
                  <a v-if="record.resourceType == 4" @click="copyUrl(record)">复制链接</a>
                  <a v-if="record.resourceType != 4" @click="setResourceToUsed(record)"
                    >载入</a
                  ><a v-if="record.resourceType == 4" @click="myViewResource(record)"
                    >预览</a
                  >
                  <!--出售和删除只有在未发布的情况可以操用-->
                  <template v-if="record.status == 0 || record.status == 2">
                    <a @click="publishMyRresource(record)">发布</a>
                  </template>
                  <template v-else>
                    <a disabled>发布</a>
                  </template>
                  <a-dropdown placement="bottomLeft" :arrow="{ pointAtCenter: true }">
                    <a class="ant-dropdown-link" @click.prevent>
                      更多
                      <DownOutlined />
                    </a>
                    <template #overlay>
                      <a-menu>
                        <template v-if="record.status == 0 || record.status == 2">
                          <a-menu-item>
                            <a @click="resourceEdit(record.id)">修改</a>
                          </a-menu-item>
                          <a-menu-item>
                            <a @click="showDeleteConfirm(record.id)">删除</a>
                          </a-menu-item>
                        </template>
                        <template v-else>
                          <a-menu-item disabled> <a>修改</a></a-menu-item>
                          <a-menu-item disabled> <a>删除</a></a-menu-item>
                          <template
                            v-if="
                              record.status == 1 &&
                              record.resourceType != 4 &&
                              record.sourceType == 1
                            "
                          >
                            <a-menu-item>
                              <a @click="recoveryMap(record.number)">回滚</a>
                            </a-menu-item>
                          </template>
                        </template>
                      </a-menu>
                    </template>
                  </a-dropdown>
                </div>
              </template>
            </template>
          </a-table>
        </a-tab-pane>
        <a-tab-pane key="2" tab="内置资源" @change="tabIndex">
          <div class="search-condition">
            <div style="width: 100%">
              <span class="condition-title">资源类型：</span>
              <a-select
                v-model:value="mapResourceType"
                :allow-clear="true"
                :options="mapTypeOptions"
                @change="mapSelectChange"
                placeholder="请选择资源类型"
                style="width: 200px"
              ></a-select>
              &nbsp;&nbsp;&nbsp;
              <span class="condition-title">适用城市：</span>
              <a-tree-select
                label-in-value
                v-model:value="cityData"
                show-search
                style="width: 200px; cursor: pointer"
                :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                placeholder="请选择适用城市"
                allow-clear
                tree-default-expand-all
                :tree-data="treeData"
                tree-line
                @change="mapSelectChange"
                tree-node-filter-prop="label"
              >
                <template #treeIcon="{ switcherCls }"
                  ><down-outlined :class="switcherCls"
                /></template>
              </a-tree-select>
              <a-button type="primary" @click="mapQuery">查询</a-button>
            </div>
          </div>
          <div style="margin-top: 10px">
            <a-table
              :columns="MapAssetsColumns"
              :data-source="mapDataSource"
              :pagination="mapPagination"
              @change="mapHandlePaginationChange"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'name'">
                  <a @click="mapViewResource(record)">{{ record.name }}</a>
                </template>
                <template v-else-if="column.key === 'sourceType'">
                  <span>
                    <a-tag :color="sourceTypeData[record.sourceType][0]">{{
                      sourceTypeData[record.sourceType][1]
                    }}</a-tag>
                  </span>
                </template>
                <template v-else-if="column.key === 'assetLevel'">
                  <span>
                    <a-tag :color="resourceTypeData[record.assetLevel][0]">{{
                      resourceTypeData[record.assetLevel][1]
                    }}</a-tag>
                  </span>
                </template>
                <template v-else-if="column.key === 'status'">
                  <span>
                    <a-tag :color="resourceStatusData[record.status][0]">{{
                      resourceStatusData[record.status][1]
                    }}</a-tag>
                  </span>
                </template>
                <template v-else-if="column.key === 'action'">
                  <div class="action" style="width: 200px">
                    <a @click="publishMapRresource(record)">发布</a>
                  </div>
                </template>
              </template>
            </a-table>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>

    <a-modal
      class="no-border-radius"
      width="100%"
      height="100%"
      :style="{
        width: '100%',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showLoadMap"
    >
      <div style="height: 92vh">
        <iframe
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          :key="iframeKey"
          scrolling="no"
          :src="url"
        ></iframe>
      </div>
    </a-modal>

    <CreateMap
      :show-model="showCreateMap"
      @close="closeCreateMap"
      @ok="createMapOK"
    ></CreateMap>

    <CreateVR
      :show-model="showCreateVr"
      @close="closeCreateVr"
      @ok="createVrOK"
    ></CreateVR>

    <a-modal
      class="no-border-radius"
      :style="{
        width: '1024px',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showResourceSell"
    >
      <component
        :is="sellResourceComponent"
        :key="sellReourceComponentKey"
        :record="sellRecord"
        @close="closeResourceSell"
        @publish="resourcePublish"
      ></component>
    </a-modal>

    <a-modal
      class="no-border-radius"
      :style="{
        width: '620px',
        paddingTop: '20px',
      }"
      :footer="null"
      centered
      v-model:open="showResourceEdit"
    >
      <EditResource :id="editId" @close="editClose" @edit="editHandler"></EditResource>
    </a-modal>
  </div>
</template>

<script lang="ts" setup>
import{findNodeByValue} from "@/shared/tree-utils";
import { ref } from "vue";
import ResourceSell from "@/views/console/my-resource/resource-sell/index.vue";
import EditResource from "@/views/console/edit-resource/index.vue";
import { getMyResourcePageList,deleteMyResource,setMyMapToUsed,recoveryMyMap,getMapAssetsRecordsPageList} from "@/api/index";
import {  message, type SelectProps,TreeSelectProps } from "ant-design-vue";
import { ResourceTypeOptions, SourceTypeOptions } from "@/shared/models/select-option";
import { MyResourceColumns,MapAssetsColumns } from "@/shared/models/table-columns";
import {
  ResourceTypeData,
  ResourceStatusData,
  SourceTypeData,
} from "@/shared/models/tag-data";
import dayjs from "dayjs";
import store from '@/store';
import CreateMap from "@/views/console/create-map/index.vue";
import CreateVR from "@/views/console/create-vr/index.vue";
import { copyToClipboard } from "@/shared/utils";
import {getResourceEditOrViewUrl} from "@/shared/utils";
import { Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { createVNode } from 'vue';
import {to62} from '@/shared/utils';
import { DownOutlined } from '@ant-design/icons-vue';
import { MapTypeOptions } from "@/shared/models/select-option";
import { CidyData } from "@/shared/city-data";

const cityData = ref<{ label: string; value: string } | null>(null);
const treeData = ref<TreeSelectProps["treeData"]>(CidyData);
const mapTypeOptions = ref<SelectProps["options"]>(MapTypeOptions);
const sellResourceComponent = ref<typeof ResourceSell | null>(null);
const sellReourceComponentKey = ref(0);


const myDataSource = ref<any[]>([]);
const mapDataSource = ref<any[]>([]);

const resourceTypeOptions = ref<SelectProps["options"]>(ResourceTypeOptions);
const sourceTypeOptions = ref<SelectProps["options"]>(SourceTypeOptions);

const myResourceType = ref(null);
const mapResourceType = ref(null);
const sourceType = ref(null);
const sourceNumber = ref(null);
const resourceTypeData = ref(ResourceTypeData);
const resourceStatusData = ref(ResourceStatusData);
const sourceTypeData = ref(SourceTypeData);
const url = ref("");
const showResourceEdit = ref(false);
const editId = ref("");

const showLoadMap = ref(false);
const showCreateMap= ref(false);
const showCreateVr = ref(false);
const iframeKey = ref(0);
const sellRecord = ref(null);
const activeKey = ref("1");

const showResourceSell=ref(false);

const editClose =()=>{
  showResourceEdit.value = false;
  editId.value = "";
}

const editHandler=()=>{
  myQuery();
  editClose();
  message.success("资源修改成功");
}

const myPagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: true,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});


const mapPagination = ref({
  current: 1,
  pageSize: 10,
  total: 0,
  showSizeChanger: false,
  showQuickJumper: true,
  showTotal: (total: number, range: [number, number]) =>
    `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`,
});

const createMap = () =>{
  showCreateMap.value = true;
}

const createVR = ()=>{
  showCreateVr.value = true;
}

const closeCreateMap = () =>{
    showCreateMap.value = false;
}

const closeCreateVr=() =>{
  showCreateVr.value = false;
}

const myHandlePaginationChange = (page: any) => {
  myPagination.value.current = page.current;
  myPagination.value.pageSize = page.pageSize;
  myQuery();
};


const mapHandlePaginationChange = (page: any) => {
  mapPagination.value.current = page.current;
  mapPagination.value.pageSize = page.pageSize;
  mapQuery();
};

const closeResourceSell = () =>{
  sellResourceComponent.value = null;
  showResourceSell.value = false;
}

const publishMyRresource = (record:any)=>{
  sellResourceComponent.value = ResourceSell;
  showResourceSell.value = true;
  sellRecord.value = record;
  sellReourceComponentKey.value++;
}


const publishMapRresource = (record:any)=>{
  sellResourceComponent.value = ResourceSell;
  showResourceSell.value = true;
  //调整Record数据
  const data :any= {
    number: 0,
    name: record.name,
    applicableCity:record.applicableCity,
    cityId : record.cityId,
    resourceType:record.assetLevel,
    originalMapId : record.id
  };

  sellRecord.value = data;
  sellReourceComponentKey.value++;
}

const resourcePublish = async () =>{
  myQuery();
  closeResourceSell();
  activeKey.value = "1";
 }


 const resourceEdit =(id:string)=>{
  showResourceEdit.value = true;
  editId.value = id;
 }

/**
 *  回滚资源，会覆盖当前版本
 *
 */
 const recoveryMap= async(number:string)=>{
  Modal.confirm({
    title: '回滚地图',
    icon: createVNode(ExclamationCircleOutlined),
    content: '该操作会将地图当前版本回滚至购买时的状态且无法撤回，请充分理解后谨慎操作。如确实要回滚，请按确认按钮。',
    okText: '确认',
    okType: 'danger',
    cancelText: '消取',
    async onOk() {
      const res: any = await recoveryMyMap(number);
        if(res.success){
          if(res.success){
            message.success("资源回滚成功");
            myQuery();
          }else {
            message.error("资源回滚失败");
          }
          return await new Promise((resolve) => {
              resolve(null);
          });
        }
    }
  });
}

/**
 * 删除资源
 */
const showDeleteConfirm = (id:string) => {
  Modal.confirm({
    title: '删除资源',
    icon: createVNode(ExclamationCircleOutlined),
    content: '该操作无法撤回，请充分理解后谨慎操作。如确实要删除，请按确认按钮。',
    okText: '确认',
    okType: 'danger',
    cancelText: '消取',
    async onOk() {
        const res: any = await deleteMyResource(id);
        if(res.success){
          myQuery();
          return await new Promise((resolve) => {
              resolve(null);
          });
        }
    }
  });
};

/**
 *
 * 设计地图
*/
const designMap=(record:any)=>{
  url.value = getResourceEditOrViewUrl(record,store.getters["auth/getToken"],'bridge');
  console.log(url.value);
  showLoadMap.value = true;
  iframeKey.value++;
}

/**
 * 载入地图
 */
const setResourceToUsed =async (record:any)=>{

  Modal.confirm({
    title: "载入地图",
    icon: createVNode(ExclamationCircleOutlined),
    content: "确定使用当前地图替换矩客星直播地图吗？",
    okText: "载入",
    async onOk() {
      const res :any = await setMyMapToUsed(record.number);
      if(res.success){
        message.success("地图载入成功");
      }else {
        message.error("地图载入失败");
      }
      return new Promise((resolve) => {
        resolve(null);
      }).catch(() => console.log("Oops errors!"));
    },
  });
}

/**
 *
 * 浏览我的资源
*/
const myViewResource=(record:any)=>{
  url.value = getResourceEditOrViewUrl(record,store.getters["auth/getToken"],'view');
  showLoadMap.value = true;
  iframeKey.value++;
}


/**
 *
 * 浏览矩形客星资源
*/
const mapViewResource=(record:any)=>{
  record.originalMapId = record.id;
  url.value = getResourceEditOrViewUrl(record,store.getters["auth/getToken"],'view');
  showLoadMap.value = true;
  iframeKey.value++;
}

/**
 *  复制到剪贴板
 */
const  copyUrl=(record:any)=> {
  var url = new URL(window.location.href);
  var protocol = url.protocol;
  var hostname = url.hostname;
  var port = url.port;
  if (port) {
    port = ":" + port;
  }
  const vrHref = `${protocol}//${hostname}${port}/vr/index/${to62(record.number)}`;
  var result = copyToClipboard(vrHref);
  if (result) {
    message.success("链接复制成功");
  }
}

const getMapAssetsRecordList =async  (data: any) => {
  const res: any = await getMapAssetsRecordsPageList(
    data,
    mapPagination.value.current,
    mapPagination.value.pageSize
  );

if(res.result){
  mapDataSource.value = res.result.items.map((item: any) => {
      item.applicableCity = findNodeByValue(CidyData as any,item.cityId)?.label;
      return item;
    });
    mapPagination.value.total =  res.result.total;
  }
};

const getMyResourceList =async  (data: any) => {
  const res: any = await getMyResourcePageList(
    data,
    myPagination.value.current,
    myPagination.value.pageSize
  );

if(res.result){
  myDataSource.value = res.result.items.map((item: any) => {
      item.expiredTime = item.expiredTime?dayjs(item.expiredTime).format("YYYY-MM-DD HH:mm"):'-';
      item.lastModificationTime = dayjs(item.lastModificationTime).format("YYYY-MM-DD HH:mm");
      return item;
    });
    myPagination.value.total =  res.result.totalCount;
  }
};

const mySelectChange = () => {
  myPagination.value.current = 1;
  myQuery();
};


const mapSelectChange = () => {
  mapPagination.value.current = 1;
  mapQuery();
};


const mapQuery = () => {
  getMapAssetsRecordList({
    resourceType: mapResourceType.value,
    cityId: cityData.value?.value,
  });
};

const myQuery = () => {
  getMyResourceList({
    resourceType: myResourceType.value,
    sourceType: sourceType.value,
    number: sourceNumber.value,
  });
};

/**
 *创建地图完成
 */
 const createMapOK = async (record:any) => {
   designMap(record);
   myQuery();
 }

 /**
  *创建Vr完成
  */
 const createVrOK = async () => {
   myQuery();
 }


 /**
  * 切换选项卡
  */
 const tabIndex = (activeKey:number)=>{
     if(activeKey == 1){
       myQuery();
     }else {
       mapQuery();
     }
 }

 myQuery();
</script>

<style lang="scss" scoped>
.main {
  width: 100%;
  min-width: 980px;
  height: 100%;
  background-color: #fff;
}

.action {
  display: flex;
  justify-content: space-between;
  width: 250px;

  a {
    margin-right: 5px;
  }
}
.sale-info {
  padding-left: 10px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  margin-bottom: 5px;

  button {
    margin-right: 10px;
  }
}

.search-condition {
  display: flex;
  justify-content: flex-start;
  padding: 0 10px;
  position: relative;
  height: 45px;
  line-height: 45px;
  width: 100%;

  button {
    margin-left: 10px;
  }
}
</style>
