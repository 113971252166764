<template>
  <div class="main-body">
    <header-tag :show-version="true">
      <div class="solt">
        <a @click="goToMyCenter">登录</a>
        <a @click="goToRegister">注册</a>
      </div>
    </header-tag>
    <div class="condition">
      <div class="trad-type" style="display: none">
        <div
          @click="swicthTradType('map')"
          :class="['trad-item', { 'trad-active': tradType == 'map' }]"
        >
          地图交易
        </div>
        <div
          @click="swicthTradType('vr')"
          :class="['trad-item', { 'trad-active': tradType == 'vr' }]"
        >
          VR交易
        </div>
      </div>
      <div class="search-condition">
        <div class="search-item" style="width: 300px">
          <span class="condition-title">适用城市</span>
          <a-tree-select
            v-model:value="cityId"
            show-search
            style="width: 200px; cursor: pointer"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            placeholder="请选择适用城市"
            allow-clear
            tree-default-expand-all
            tree-line
            :tree-data="treeData"
            @change="onChangeQuery"
            tree-node-filter-prop="label"
          >
          </a-tree-select>
        </div>
        <div class="search-item" style="width: 300px">
          <span class="condition-title">资源类型</span>
          <a-select
            v-model:value="mapType"
            :allow-clear="true"
            :options="resourceTypeOptions"
            placeholder="请选择资源类型"
            style="width: 200px"
            @change="onChangeQuery"
          ></a-select>
        </div>
        <div class="search-item" style="width: 310px">
          <span class="condition-title" style="float: left">价格区间</span>
          <!-- <a-input-number v-model:value="value2[0]" :min="0" :controls="false" />
          <span class="white-line"></span>
          <a-input-number v-model:value="value2[1]" :min="0" :controls="false" /> -->
          <div style="float: left; margin-right: 10px; display: flex">
            <!-- <a-input-number
              style="width: 60px; float: left"
              v-model:value="value2[0]"
              :min="0"
              :max="1000"
              :controls="false"
            /> -->
            <!-- <div
              style="
                width: 48px;
                height: 45px;
                line-height: 45px;
                text-align: start;
                margin-right: 5px;
              "
            >
              {{ valueRange[0] + "元" }}
            </div> -->
            <a-slider
              style="width: 100px; top: 6px"
              v-model:value="valueRange"
              :step="1"
              @afterChange="onChangeQuery"
              range
              :max="1000"
            >
            </a-slider>
            <div style="height: 45px; line-height: 45px; margin-left: 10px">
              {{ valueRange[0] + "元" }} - {{ valueRange[1] + "元" }}
            </div>
            <!-- <a-input-number
              style="width: 60px; float: left"
              v-model:value="value2[1]"
              :min="0"
              :max="1000"
              :controls="false"
            /> -->
          </div>
        </div>
        <div class="search-button">
          <a-button
            type="primary"
            style="position: relative; top: 6px"
            @click="onResetQuery"
            :loading="queryLoading"
            >重置</a-button
          >
        </div>
      </div>
    </div>
    <a-skeleton avatar active :paragraph="{ rows: 4 }" v-if="loading" />
    <template v-else>
      <template v-if="dataSource && dataSource.length > 0">
        <div class="map-container">
          <div class="map-item" v-for="(item, index) in dataSource" :key="index">
            <div class="map-img" @click="goToResource(item.number)">
              <img
                style="width: 352px; height: 220px"
                v-if="item.imageUrl != null && item.imageUrl.length > 0"
                :src="item.imageUrl"
              />
              <img v-else :src="getImageSrc(index)" />
            </div>
            <div style="margin-left: 10px">
              <resource-tag :data="item"></resource-tag>
            </div>
          </div>
        </div>
        <div class="footer">
          <a-pagination
            show-size-changer
            v-model:current="current"
            v-model:pageSize="pageSize"
            v-model:total="total"
            @change="onPagedChange"
            v-if="totalPages > 1"
            :showTotal="showPageMessage"
            show-quick-jumper
          />
        </div>
        <div class="copright">武汉艾得科技有限公司版权所有©2024</div>
      </template>
      <template v-else>
        <a-empty style="margin-top: 120px" />
      </template>
    </template>

    <a-float-button @click="goToMyCenter" type="primary" :style="{ right: '24px' }">
      <template #icon>
        <DesktopOutlined />
      </template>
    </a-float-button>

    <a-modal
      class="no-border-radius"
      :footer="null"
      :style="{
        width: '100vw',
        height: 'calc(100vh - 20px)',
        top: '20px',
      }"
      v-model:open="showResource"
      transitionName=""
      @cancel="closeResource"
    >
      <component
        :is="currentComponent"
        :key="componentKey"
        :number="resourceNumber"
        @close="closeResource"
      ></component>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, onMounted, onActivated } from "vue";
import { useRouter } from "vue-router";
import ResourceTag from "@/components/map/ResourceTag.vue";
import HeaderTag from "@/components/HeaderTag.vue";
import ResourceCard from "@/shared/models/resource-card";
import { getResourceInfoPageList } from "@/api/index";
import dayjs from "dayjs";
import { CidyData } from "@/shared/city-data";
import type { TreeSelectProps, SelectProps } from "ant-design-vue";
import { MapTypeOptions, VrTypeOptions } from "@/shared/models/select-option";
import { ResourceTypeData } from "@/shared/models/tag-data";
import Resource from "@/views/map/resource/index.vue";
import { DesktopOutlined } from "@ant-design/icons-vue";

/**
 * 定义资源类型
 */
type ResourceType = "map" | "vr";
const resourceTypeOptions = ref<SelectProps["options"]>(MapTypeOptions);
const treeData = ref<TreeSelectProps["treeData"]>(CidyData);

const router = useRouter();
const currentComponent = ref<typeof Resource | null>(null);
const componentKey = ref(0);
const resourceNumber = ref("");

const dataSource = ref<ResourceCard[]>([]);
const pageSize = ref(20);
const current = ref(1);
const total = ref(0);
const tradType = ref<ResourceType>("map");
const mapType = ref(null);
const cityId = ref(null);
const loading = ref(true);
const queryLoading = ref(false);
const showResource = ref(false);

const totalPages = computed(() => Math.ceil(total.value / pageSize.value));

const valueRange = ref<[number, number]>([0, 1000]);

onMounted(() => {
  document.title = "交易大厅-国内首个房产资源交易平台";
});

onActivated(() => {
  const buyNumber = sessionStorage.getItem("buyNumber");
  if (buyNumber) {
    goToResource(buyNumber);
    sessionStorage.removeItem("buyNumber");
  }
});

const showPageMessage = (total: number, range: [number, number]) =>
  `显示 ${range[0]}-${range[1]} 条，共 ${total} 条`;

const onPagedChange = (index: number, size: number) => {
  current.value = index;
  pageSize.value = size;
  query();
};

const closeResource = () => {
  showResource.value = false;
  currentComponent.value = null;
  componentKey.value++;
};

/**
 * 获取图片资源
 * @param index 序号，用于匹配文件名后面的数据
 */
const getImageSrc = (index: number) => {
  let sequence = index % 6;
  const paddedSequence = String(sequence + 1).padStart(2, "0");
  if (tradType.value == "map") {
    return require(`@/assets/images/@map-${paddedSequence}.png`);
  }
  return require(`@/assets/images/vr-${paddedSequence}.png`);
};

/**
 * 选择资源类型
 * @param type
 */
const swicthTradType = (type: ResourceType) => {
  resourceTypeOptions.value = type == "map" ? MapTypeOptions : VrTypeOptions;
  mapType.value = null;
  cityId.value = null;
  tradType.value = type;
  onChangeQuery();
};

const onResetQuery = () => {
  cityId.value = null;
  valueRange.value = [0, 1000];
  mapType.value = null;
  onChangeQuery();
};

const onChangeQuery = () => {
  current.value = 1;
  query();
};

const query = () => {
  queryLoading.value = true;
  const req = {
    CityId: cityId.value,
    MinSalePrice: valueRange.value[0],
    MaxSalePrice: valueRange.value[1],
    ResourceType: mapType.value,
    TradType: tradType.value,
  };
  getResourceInfoList(req);
  queryLoading.value = false;
};

/**
 * 导航到指定资源，以便购买
 * @param id 资源id
 */
const goToResource = (number: string) => {
  resourceNumber.value = number;
  showResource.value = true;
  currentComponent.value = Resource;
};

const goToMyCenter = () => {
  router.push("/my-home");
};

const goToRegister = () => {
  router.push("/register");
};

const getResourceInfoList = async (data: any) => {
  const res: any = await getResourceInfoPageList(data, current.value, pageSize.value);
  if (res.result) {
    dataSource.value = res.result.items.map((item: any) => {
      const model: ResourceCard = {
        id: item.id,
        number: item.number,
        title: `[${item.number}] ${item.publishTitle}`,
        region: item.applicableCity,
        type: ResourceTypeData[item.resourceType][1],
        price: item.salePrice,
        author: item.author,
        imageUrl: item.imageUrl,
        avatarUrl: item.avatarUrl,
        usableMonths: item.usableMonths,
        date: dayjs(item.publishTime).format("YYYY-MM-DD"),
      };
      return model;
    });
    total.value = res.result.totalCount;
    loading.value = false;
  }
};

query();
</script>

<style lang="scss" scoped>
.condition-title {
  display: inline-block;
  width: 70px;
  height: 45px;
  line-height: 45px;
}

.white-line {
  display: inline-block;
  width: 10px;
  height: 1px;
  border: 1px solid #979797;
  margin: 0px 5px;
}

.main-body {
  min-width: 1150px;
  width: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
}

.condition {
  width: 100%;
  height: 120px;
  padding: 20px 20px;
  font-weight: 500;
  font-size: 14px;
  color: #262626;

  .trad-type {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 192px;
    height: 32px;
    line-height: 32px;
    padding: 0 2px;
    background: #f2f2f2;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;

    .trad-active {
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }

    .trad-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 96px;
      height: 28px;
      line-height: 28px;
      font-weight: 400;
      font-size: 14px;
      color: #595959;
      text-align: center;
      font-style: normal;
      user-select: none;
    }
  }

  .search-condition {
    display: flex;
    justify-content: flex-start;
    padding: 0 2px;
    position: relative;
    top: 20px;
  }
}

.map-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 5px;
}

:where(.ant-modal) {
  min-width: 1200px;
}

@media screen and (max-width: 450px) {
  .no-border-radius {
    .ant-modal-content {
      padding: 16px;
    }
  }

  .search-condition {
    flex-direction: column;

    .search-item {
      height: 45px;
      line-height: 45px;
    }

    .search-button {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
    }
  }

  .condition {
    height: 250px;
  }
  .main-body {
    min-width: 350px;
    width: 100%;
    margin: 0;
    padding: 0;
    background: #ffffff;
  }
  .map-container {
    flex-direction: column;
    align-items: center;
  }
}

.map-item {
  width: 356px;
  height: 368px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d7d7d7;
  margin: 10px 10px;
  padding: 1px;
  .map-img {
    //height: 65%;
    cursor: pointer;

    img {
      border-top-left-radius: 9px;
      border-top-right-radius: 9px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .map-bottom {
    height: 46px;
    display: flex;
    justify-content: flex-start;
    margin-top: 5px;
    margin-left: 10px;
  }
}

.footer {
  display: flex;
  height: 100px;
  flex-direction: column;
  align-items: end;
  margin: 0 20px;
  padding-right: 20px;
}

.copright {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: #595959;
  font-weight: 500;
  font-size: 16px;
  background-color: #fff;
}

.solt {
  position: relative;
  top: 10px;
  margin-right: 10px;

  a {
    color: #fff;
    text-decoration: none;
    margin-right: 10px;
  }
}
</style>
