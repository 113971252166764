import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Index from '@/views/map/index.vue'
import Resource from "@/views/map/resource/index.vue";
import MyConsole from '@/views/console/my-console/index.vue';
import MyResource from '@/views/console/my-resource/index.vue';
import MyHome from '@/views/console/my-home/index.vue';
import MyPublish from '@/views/console/my-publish/index.vue';
import MyInfo from '@/views/console/my-info/index.vue';
import MyTransaction from '@/views/console/my-transaction/index.vue';
import UpdatePassword from '@/views/console/update-password/index.vue';
import Login from '@/views/console/login/index.vue'
import store from '@/store';
import ResourcePublish from '@/views/console/my-home/resource-publish/index.vue'
import Register from '@/views/register/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta:{keepAlive:true}
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/resource',
    name: 'Resource',
    component: Resource,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/my-console',
    name: 'MyConsole',
    component: MyConsole,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/my-home',
        name: 'MyHome',
        component: MyHome
      },
      {
        path: '/my-resource',
        name: 'MyResource',
        component: MyResource
      },
      {
        path: '/my-publish',
        name: 'MyPublish',
        component: MyPublish
      },
      {
        path: '/my-transaction',
        name: 'MyTransaction',
        component: MyTransaction
      },
      {
        path: '/update-pssword',
        name: 'UpdatePassword',
        component: UpdatePassword
      },
      {
        path: '/resource-publish',
        name: 'ResourcePublish',
        component: ResourcePublish
      }, 
      {
        path: '/my-info',
        name: 'MyInfo',
        component: MyInfo
      }
    ]
  }
]

const router = createRouter({
  //history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})


router.beforeEach((to, from, next) => {
  const isAuthenticated = checkIfUserIsAuthenticated()
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({
      name: 'Login',
      query: { redirect: to.fullPath },
    })
  } else {
    next()
  }
})

const checkIfUserIsAuthenticated = () => {
  return store.getters['auth/isLogined'];
}

export default router
