import CryptoUtils from '@/shared/crypto-utils'

export interface User {
    id: string,
    uid: string;
    nickname: string;
    avatar: string;
    isAdmin: boolean
}

export interface AuthState {
    uid: string | null;
    user: User | null;
    token: string | null;
}

const state = (): AuthState => ({
    user: JSON.parse(CryptoUtils.decrypt(sessionStorage.getItem('user')) ?? "{}"),
    token: sessionStorage.getItem('token'),
    uid: localStorage.getItem('uid')
});

const mutations = {
    setUser(state: AuthState, user: User) {
        state.user = user;
        state.uid = user.id;       
        const userdata = CryptoUtils.encrypt(JSON.stringify(user)) ?? "";
        localStorage.setItem('uid', user.id);
        sessionStorage.setItem('user', userdata);
    },

    setToken(state: AuthState, token: string) {
        state.token = token;
        sessionStorage.setItem('token', token);
    },

    clearAuth(state: AuthState) {
        state.user = null;
        state.token = null;
        state.uid = null;
        sessionStorage.clear();
        localStorage.removeItem("uid");
    },
};

const actions = {
    /**
     * 登录
     * @param param0  
     * @param param1 
     */
    login({ commit }: any, { user, token }: { user: User; token: string }) {
        commit('setUser', user);
        commit('setToken', token);
    },

    /**
     * 更新用户
     * @param param0 
     * @param param1 
     */
    setUser({ commit }: any, { user }: { user: User }) {
        commit('setUser', user);
    },

    /**
     * 注销
     * @param param0
     */
    logout({ commit }: any) {
        commit('clearAuth');
    },
};

const getters = {
    isLogined: (state: AuthState): boolean => {
        if (state.token && state.uid && state.user && state.uid == state.user.id) {
            return true;
        }
        return false;
    },
    getUser: (state: AuthState): User | null => state.user,
    getToken: (state: AuthState): string | null => state.token,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};