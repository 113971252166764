import { httpRequest } from "../http/request";

const controllerName = 'account'

/**
 * 获取验证码
 * @param id
 * @returns {*}
 */
export function getCode(mobile: string) {
    return httpRequest({
        url: `/${controllerName}/getCode?mobile=${mobile}`,
        method: "get"
    });
}

/**
 * 账号登录
 * @param account 账号
 * @param password 密码
 * @returns  {*}
 */
export function login(account: string, password: string) {
    return httpRequest({
        url: `/${controllerName}/login`,
        method: "post",
        data: {
            account, password
        }
    });
}

/**
 * 验证短信
 * @param mobile 手机号码
 * @param code 验证码
 * @returns  {*}
 */
export function smsLogin(mobile: string, code: string) {
    return httpRequest({
        url: `/${controllerName}/smsLogin`,
        method: "post",
        data: {
            mobile, code
        }
    });
}



/**
 * 修改密码
 * @param password 旧密码
 * @param newPassword 新密码
 * @param confirmPassword 确认密码
 * @returns 
 */
export function updatePassword(password: string, newPassword: string, confirmPassword: string) {
    return httpRequest({
        url: `/${controllerName}/updatePassword`,
        method: "post",
        data: {
            password, newPassword, confirmPassword
        }
    });
}

/**
 * 更新用户信息
 * @param data 用户信息
 * @returns 
 */
export function updateInfo(data: any) {
    return httpRequest({
        url: `/${controllerName}/updateInfo`,
        method: "post",
        data: {
            ...data
        }
    });
}




/**
 * 用户注册
 * @param data 用户信息
 * @returns 
 */
export function register(data: any) {
    return httpRequest({
        url: `/${controllerName}/register`,
        method: "post",
        data: {
            ...data
        }
    });
}


/**
 * 上传图片
 * @param formData 表单 
 * @returns 
 */
export function uploadImage(formData: any) {
    return httpRequest({
        url: `/${controllerName}/uploadImage`,
        method: "post",
        data: formData,
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
}