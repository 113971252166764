<template>
  <div class="main-container">
    <div class="main">
      <div class="main-left">
        <iframe
          id="my-node"
          style="width: 100%; height: 100%; border: none; overflow: hidden"
          scrolling="no"
          :src="url"
        ></iframe>
      </div>
      <div class="main-right">
        <div class="map-image">
          <img
            :src="mapInfo.imageUrl"
            v-if="mapInfo.imageUrl != null && mapInfo.imageUrl.length > 0"
          />
          <img v-else :src="imgUrl" />
        </div>
        <div class="map-info">
          <a-skeleton active v-if="loading" />
          <resource-tag :data="mapInfo" v-else></resource-tag>
        </div>
        <div class="map-trading">
          <template v-if="btnBuyText.length > 0 || btnUseText.length > 0">
            <a-button
              v-if="hasNotBuy"
              @click="buyThisMap"
              type="primary"
              class="map-button"
              :loading="btnloading"
              >{{ btnBuyText }}</a-button
            >
            <a-button
              v-else
              type="primary"
              class="map-button"
              @click="useThisMap"
              :loading="btnloading"
              >{{ btnUseText }}</a-button
            >
          </template>
        </div>
        <div class="mainze">
          <div class="wenzi">
            <span>免责声明：</span>
            <br />1、通过本平台购买的资源仅限在我司软件产品体系内<br /><em
              style="padding-left: 20px; font-style: normal; letter-spacing: 0.38px"
              >使用，若未经许可用于其它场景将追责处理；</em
            >
            <br />2、买卖双方自愿交易原则，定价及交易内容跟平台无<br /><em
              style="padding-left: 20px; font-style: normal; letter-spacing: 0.8px"
              >关，交易成功后将不支持退费，请在购买前认真<br /><em
                style="padding-left: 20px; font-style: normal"
              ></em
              >了解资源；</em
            >
            <br />3、若绕过平台进行交易，双方权益将不受保护。
          </div>
        </div>
      </div>
    </div>
    <wecaht-pay v-if="showMask" :qrcode-url="qrcodeUrl" @close="closeMask" />
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onBeforeUnmount } from "vue";
import ResourceTag from "@/components/map/ResourceTag.vue";
import WecahtPay from "@/components/map/WecahtPay.vue";
import { useRouter } from "vue-router";
import ResourceCard from "@/shared/models/resource-card";
import { getResourceInfoByNumber, getPayQrCode, buy, hasBought } from "@/api/index";
import dayjs from "dayjs";
import store from "@/store";
import { message } from "ant-design-vue";
import { getMyResourceByNumber, setMyMapToUsed } from "@/api/index";
import mapViewUrl from "@/assets/images/@map-preview.png";
import vrViewUrl from "@/assets/images/@VR-preview.png";
import { ResourceTypeData } from "@/shared/models/tag-data";
import { copyToClipboard } from "@/shared/utils";
import { getResourceViewUrl, getResourceEditOrViewUrl } from "@/shared/utils";
import { to62 } from "@/shared/utils";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";

const emit = defineEmits(["close"]);
const props = defineProps({
  number: {
    type: String,
    required: true,
  },
});

const router = useRouter();
const showMask = ref(false);
const qrcodeUrl = ref("");
const loading = ref(true);
const btnloading = ref(false);
const url = ref("");
const btnBuyText = ref("");
const btnUseText = ref("");

const imgUrl = ref(mapViewUrl);
const hasNotBuy = ref(true);
let resourceInfo: any = null;

const mapInfo = ref<ResourceCard>({
  id: "",
  number: "",
  title: "",
  region: "",
  type: "",
  price: 0,
  imageUrl: "",
  avatarUrl: "",
  usableMonths: 0,
  author: "",
  date: "",
});

onMounted(() => {
  watch(
    () => props.number,
    async (newValue) => {
      if (newValue) {
        getPublishResourceInfo();
      }
    },
    { immediate: true }
  );
});

/**
 * 载入地图或复制链接
 */
const useThisMap = () => {
  if (resourceInfo.resourceType == 4) {
    var url = new URL(window.location.href);
    var protocol = url.protocol;
    var hostname = url.hostname;
    var port = url.port;
    if (port) {
      port = ":" + port;
    }
    const vrHref = `${protocol}//${hostname}${port}/vr/index/${to62(
      resourceInfo.number
    )}`;
    var result = copyToClipboard(vrHref);
    if (result) {
      message.success("链接复制成功");
    }
  } else {
    setMapToUsed(resourceInfo);
  }
};

/**
 * 使用地图
 */
const setMapToUsed = async (record: any) => {
  const res: any = await setMyMapToUsed(record.number);
  if (res.success) {
    message.success("载入成功，请登录矩客星地图管理查看！");
  } else {
    message.error("载入失败，请与平台管理员联系！");
  }
};

/**
 * 购买此地图
 */
const buyThisMap = async () => {
  if (!checkIfLogined()) {
    emit("close");
    router.push({
      name: "Login",
      query: { redirect: "/", q: resourceInfo.number },
    });
    return;
  }

  const user = store.getters["auth/getUser"];

  if (user.id == resourceInfo.authorId) {
    message.error("不允许购买自己发布的资源");
    return;
  }

  let tip =
    "购买前请认真阅读免责声明并充分理解后再购买，如有任何问题请与我们联系。确认购买吗？";
  if (resourceInfo.cityId != user.cityId) {
    tip = "您当前购买的资源不是您所在城市的，确定要购买吗？";
  }

  Modal.confirm({
    title: "购买确认",
    icon: createVNode(ExclamationCircleOutlined),
    content: tip,
    okText: "购买",
    async onOk() {
      btnloading.value = true;
      const number: string = props.number;
      if (mapInfo.value.price == 0) {
        const res: any = await buy(number);
        console.log(res);
        btnloading.value = false;
        if (res.success) {
          loadMyresource(number);
          message.success("资源购买成功");
        }
      } else {
        showQrcode(number);
      }
      return new Promise((resolve) => {
        resolve(null);
      }).catch(() => console.log("Oops errors!"));
    },
  });
};

let intervalId: any = null;

/**
 * 显示支付码
 */
const showQrcode = async (number: string) => {
  const res: any = await getPayQrCode(number);
  btnloading.value = false;
  if (res.success) {
    qrcodeUrl.value = res.result.qrcodeUrl;
    showMask.value = true;

    // 每2秒查询支付状态，如果购买成功后，关闭二维码
    if (null == intervalId) {
      intervalId = setInterval(async () => {
        const hasBuy: any = await hasBought(number);
        if (hasBuy.success && hasBuy.result) {
          setUrlAndButtonText(true);
          //关闭二维码和时钟
          closeMask();
          message.success("资源购买成功");
        }
      }, 1000 * 2);
    }
  }
};

/**
 * 获取用户是否登录
 */
const checkIfLogined = () => {
  return store.getters["auth/isLogined"];
};

/**
 * 关闭二维码和时钟
 */
const closeMask = () => {
  clearTimer();
  showMask.value = false;
};

//消除时钟
const clearTimer = () => {
  if (intervalId) {
    clearInterval(intervalId);
    intervalId = null;
  }
};

onBeforeUnmount(() => {
  clearTimer();
});

/**
 * 获取资源交易信息
 */
const getPublishResourceInfo = () => {
  const number: string = props.number;
  getResourceInfoByNumber(number).then((res: any) => {
    if (res.success) {
      const model: ResourceCard = {
        id: res.result.id,
        number: res.result.number,
        title: `[${res.result.number}] ${res.result.publishTitle}`,
        region: res.result.applicableCity,
        type: ResourceTypeData[res.result.resourceType][1],
        price: res.result.salePrice,
        author: res.result.author,
        imageUrl: res.result.imageUrl,
        avatarUrl: res.result.avatarUrl,
        usableMonths: res.result.usableMonths,
        date: dayjs(res.result.publishTime).format("YYYY-MM-DD"),
      };
      mapInfo.value = model;
      loadMyresource(number);
      loading.value = false;
    }
  });
};

/**
 * 设置资源链接和按钮文字
 */
const setUrlAndButtonText = (isBuy: boolean) => {
  if (isBuy) {
    hasNotBuy.value = false;
    const record = Object.assign({}, resourceInfo);
    record.originalMapId = resourceInfo.publishMapId;
    url.value = getResourceEditOrViewUrl(record, store.getters["auth/getToken"], "view");
    imgUrl.value = resourceInfo.resourceType == 4 ? vrViewUrl : mapViewUrl;
    btnUseText.value = resourceInfo.resourceType == 4 ? "复制链接" : "载入此地图";
  } else {
    hasNotBuy.value = true;
    url.value = getResourceViewUrl(resourceInfo, "2");
    imgUrl.value = resourceInfo.resourceType == 4 ? vrViewUrl : mapViewUrl;
    btnBuyText.value = resourceInfo.resourceType == 4 ? "购买此VR" : "购买此地图";
  }
};

/**
 * 获取资源原始信息
 */
const loadMyresource = async (number: string) => {
  const res: any = await getMyResourceByNumber(number);
  if (res.success) {
    resourceInfo = res.result;
    //判断有没有登录
    if (!checkIfLogined()) {
      setUrlAndButtonText(false);
    } else {
      const hasBuy: any = await hasBought(number);
      if (hasBuy.success && hasBuy.result) {
        setUrlAndButtonText(true);
      } else {
        setUrlAndButtonText(false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-container {
  width: 100%;
  height: calc(100vh - 80px);
  margin: 0;
  padding: 0;
  background: #ffffff;
}

.main {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;

  .main-left {
    width: 100%;
    overflow: hidden;
  }

  .main-right {
    width: 450px;
    height: 100%;
    padding: 10px 20px 20px 20px;

    .map-image {
      width: 360px;
      height: 224px;
      border-radius: 8px;

      img {
        width: 360px;
        height: 226px;
      }
    }

    .map-info {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 0 5px;
    }
  }
}
.map-trading {
  height: 120px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map-button {
  width: 220px;
  height: 38px;
  background: #2353f3;
  border-radius: 5px;
  color: #fff;
  border: none;
  outline: none;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #f66;
  }
}

.mainze {
  width: 360px;
  height: 182px;
  background: #f8f8f8;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  .wenzi {
    width: 335px;
    height: 180px;
    font-weight: 500;
    font-size: 14px;
    color: #595959;
    line-height: 24px;
    text-align: left;
    font-style: normal;
    overflow: hidden;
    white-space: nowrap;
    margin-top: 10px;

    span {
      font-weight: bold;
      white-space: nowrap;
    }
  }
}

@media screen and (max-width: 450px) {
  .main {
    .main-left {
      width: 0px;
    }
    .main-right {
      width: 350px;
      padding: 0;

      .map-image {
        width: 350px;

        img {
          width: 350px;
        }
      }

      .mainze {
        width: 350px;
      }
    }
  }
}
</style>
