<template>
  <div class="edit-main" :style="{ paddingTop: showVrHref ? '30px' : '60px' }">
    <div class="item">
      <span class="condition-title"><span>*</span>名称：</span>
      <a-input
        style="width: 200px"
        placeholder="请选填名称"
        v-model:value="resourceName"
      ></a-input>
    </div>
    <div class="item" v-if="showVrHref">
      <span class="condition-title"><span>*</span>链接：</span>
      <a-input
        style="width: 200px"
        placeholder="请选填链接"
        v-model:value="vrHref"
      ></a-input>
    </div>
    <div class="edit-action">
      <a-button style="margin-right: 20px" @click="close"> 取消 </a-button>
      <a-button type="primary" @click="edit"> 修改 </a-button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, watch, onMounted } from "vue";
import { message } from "ant-design-vue";
import { updateMyResource, getMyResourceById } from "@/api/index";

const props = defineProps({
  id: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close", "edit"]);

const data = ref<any>();

const close = () => {
  emit("close");
};

const edit = () => {
  onOk();
};

onMounted(() => {
  watch(
    () => props.id,
    async (newValue) => {
      if (newValue) {
        load(newValue);
      }
    },
    { immediate: true }
  );
});

const load = async (id: string) => {
  const res: any = await getMyResourceById(id);
  showVrHref.value = false;
  if (res.success) {
    const { result: record } = res;
    resourceName.value = record.resourceName;
    vrHref.value = record.vrHref;
    if (record.resourceType == 4) {
      showVrHref.value = true;
    }
    data.value = record;
  }
};

const showVrHref = ref(false);
const resourceName = ref<string | null>(null);
const vrHref = ref<string | null>(null);

const onOk = async () => {
  if (resourceName.value == null || resourceName.value == "") {
    message.error("请输入名称");
    return;
  }

  if (data.value.resourceType == 4 && showVrHref.value && vrHref.value == null) {
    message.error("请输入链接");
    return;
  }

  if (data.value.resourceType == 4 && !isValidURL(vrHref.value)) {
    message.error("请检查VR链接");
    return;
  }

  const body = {
    id: data.value.id,
    resourceName: resourceName.value,
    vrHref: vrHref.value,
  };

  const res: any = await updateMyResource(body);
  if (res.success) {
    emit("edit");
  }
};

const isValidURL = (url: string | null): boolean => {
  if (null == url) return false;
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" +
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" +
      "((\\d{1,3}\\.){3}\\d{1,3}))" +
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" +
      "(\\?[;&a-z\\d%_.~+=-]*)?" +
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );
  return !!pattern.test(url);
};
</script>

<style lang="scss" scoped>
.edit-action {
  width: 580px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.edit-main {
  width: 580px;
  height: 250px;
  padding-top: 30px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.item {
  margin: 5px 0 0 100px;
  height: 55px;
  line-height: 55px;

  .condition-title {
    display: inline-block;
    width: 100px;
    text-align: right;

    span {
      color: #ff0000;
      margin-right: 5px;
    }
  }
}
</style>
