import { httpRequest } from "../http/request";

const controllerName = 'weChatOrder'


/**
 * 获取交易列表
 * @param data
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function getPayQrCode(number: string) {
    return httpRequest({
        url: `/${controllerName}/qrCode`,
        method: "post",
        data: { number }
    });
}