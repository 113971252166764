<template>
  <div class="mask">
    <!-- 遮罩层 -->
    <div class="overlay"></div>
    <!-- 二维码 -->
    <div class="close-popup-btn" @click="closeMask"></div>
    <div class="mask-qrcode">
      <div class="pay-tip">请用微信扫码完成支付</div>
      <div class="qrcode">
        <a-qrcode :value="qrcodeUrl" :bordered="false" :size="225" />
      </div>
      <div class="wachat">
        <img src="@/assets/images/wechat.png" />
        <div class="pay-text">微信支付</div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  qrcodeUrl: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

function closeMask() {
  emit("close");
}
</script>

<style lang="scss" scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}

.close-popup-btn {
  position: relative;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgc3R5bGU9ImZpbGw6I2ZmZiIgY3g9IjI1IiBjeT0iMjUiIHI9IjI1Ii8+PGNpcmNsZSBzdHlsZT0iZmlsbDojNzg3ODc4IiBjeD0iMjUiIGN5PSIyNSIgcj0iMjAuNSIvPjxwYXRoIHN0eWxlPSJmaWxsOiNmZmYiIGQ9Im0zMi4wNzEgMzQuODk5LTE2Ljk3LTE2Ljk3YTIgMiAwIDEgMSAyLjgyOC0yLjgyOEwzNC45IDMyLjA3MmEyIDIgMCAxIDEtMi44MjkgMi44Mjd6Ii8+PHBhdGggc3R5bGU9ImZpbGw6I2ZmZiIgZD0ibTM0Ljg5OSAxNy45MjktMTYuOTcgMTYuOTdhMiAyIDAgMSAxLTIuODI4LTIuODI4TDMyLjA3MiAxNS4xYTIgMiAwIDEgMSAyLjgyNyAyLjgyOXoiLz48L3N2Zz4=);
  background-position: center;
  background-repeat: no-repeat;
  transition: transform 0.4s ease-in-out;
  width: 30px;
  height: 30px;
  top: -200px;
  left: 340px;
  z-index: 10002;
  cursor: pointer;

  &:hover {
    transform: rotate(180deg);
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTAgNTAiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxjaXJjbGUgc3R5bGU9ImZpbGw6I2ZmZiIgY3g9IjI1IiBjeT0iMjUiIHI9IjI1Ii8+PGNpcmNsZSBzdHlsZT0iZmlsbDojZjEyOTMwIiBjeD0iMjUiIGN5PSIyNSIgcj0iMjAuNSIvPjxwYXRoIHN0eWxlPSJmaWxsOiNmZmYiIGQ9Im0zMi4wNzEgMzQuODk5LTE2Ljk3LTE2Ljk3YTIgMiAwIDEgMSAyLjgyOC0yLjgyOEwzNC45IDMyLjA3MmEyIDIgMCAxIDEtMi44MjkgMi44Mjd6Ii8+PHBhdGggc3R5bGU9ImZpbGw6I2ZmZiIgZD0ibTM0Ljg5OSAxNy45MjktMTYuOTcgMTYuOTdhMiAyIDAgMSAxLTIuODI4LTIuODI4TDMyLjA3MiAxNS4xYTIgMiAwIDEgMSAyLjgyNyAyLjgyOXoiLz48L3N2Zz4=);
  }
}

.mask-qrcode {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 400px;
  width: 325px;
  background-color: #2aae67;
  z-index: 10000;

  .pay-tip {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 20px;
    margin: 25px 0;
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
    line-height: 30px;
  }

  .qrcode {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 240px;
    height: 240px;
    background: #ffffff;
    border-radius: 10px;

    .qrcode-img {
      max-width: 80%;
      max-height: 80%;
      background: #ffffff;
      border-radius: 10px;
      z-index: 10001;
    }
  }

  .pay-text {
    width: 104px;
    height: 37px;
    font-weight: 500;
    font-size: 26px;
    color: #ffffff;
    line-height: 37px;
    text-align: left;
    font-style: normal;
  }

  .wachat {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;

    img {
      margin-right: 10px;
    }
  }
}
</style>
