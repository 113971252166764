<template>
  <div class="body-content">
    <div class="top-left">
      <div class="logo">
        <img src="../../../assets/images/login-logo.png" />
        <div class="version">交易平台</div>
      </div>
      <div class="title">房产行业资源整合平台</div>
    </div>
    <div style="display: flex">
      <div class="group_3 flex-col">
        <div class="image-wrapper_2 flex-row">
          <img src="../../../assets/images/login/login-bg.jpg" />

          <img
            class="image_2"
            referrerpolicy="no-referrer"
            src="../../../assets/images/login/login-build.png"
          />
        </div>
      </div>
      <div class="flex-col" style="position: relative; left: 294px; top: -95px">
        <img
          class="image_3"
          referrerpolicy="no-referrer"
          src="../../../assets/images/login/login-ribbon.png"
        />
        <img
          class="image_4"
          referrerpolicy="no-referrer"
          src="../../../assets/images/login/login-build-2.png"
        />
      </div>
    </div>
    <div class="login">
      <div class="login-header">
        <div class="login-content">矩客星营销获客管理系统</div>
      </div>
      <div class="login-type">
        <div
          @click="swicthLoginType('account')"
          :class="['login-item', { 'login-active': loginType == 'account' }]"
        >
          <div style="login-text">账号登录</div>
        </div>
        <div
          @click="swicthLoginType('mobile')"
          :class="['login-item', { 'login-active': loginType == 'mobile' }]"
        >
          <div class="login-text">短信登录</div>
        </div>
      </div>
      <template v-if="loginType == 'account'">
        <img class="split-line" src="../../../assets//images/split-line.png" />
        <a-input
          class="user-account"
          ref="accountInput"
          v-model:value="account"
          placeholder="请输入账号"
          @keydown.enter="handleEnter"
        />
        <img class="split-line2" src="../../../assets//images/split-line.png" />
        <a-input-password
          class="user-password"
          v-model:value="password"
          ref="passwordInput"
          placeholder="请输入密码"
          @keydown.enter="handleEnter"
        >
        </a-input-password>
      </template>

      <template v-if="loginType == 'mobile'">
        <img class="split-line" src="../../../assets//images/split-line.png" />
        <a-input
          class="phone-input"
          maxlength="11"
          v-model:value="mobile"
          ref="mobileInput"
          placeholder="请输入手机号码"
          @keydown.enter="handleEnter"
        />
        <div class="code-input">
          <a-input
            class="code-input-left"
            v-model:value="code"
            maxlength="6"
            ref="codeInput"
            placeholder="请输入验证码"
            @keydown.enter="handleEnter"
          >
          </a-input>
          <button
            class="code-input-right normal-button"
            :class="[
              'code-input-right',
              disableButton == false ? 'normal-button' : 'disabled-button',
            ]"
            :disabled="disableButton"
            @click="getCodeHandler"
          >
            {{ codeText }}
          </button>
        </div>
      </template>
      <a-button class="button-handle" :loading="loading" @click="loginHandler"
        >登录</a-button
      >

      <div class="register"><a @click="register">销讲通注册</a></div>
    </div>

    <div class="footer">武汉艾得科技有限公司版权所有©2024</div>
  </div>
</template>

<script lang="ts" setup>
import { useRouter, useRoute } from "vue-router";
import { ref, onMounted } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { getCode, login, smsLogin } from "@/api/index";

type LoginType = "account" | "mobile";

const router = useRouter();
const route = useRoute();
const store = useStore();

const account = ref<string>("");
const password = ref<string>("");

const mobile = ref<string>("");
const code = ref<string>("");
var loginType = ref<LoginType>("account");

const disableButton = ref(false);
const codeText = ref("获取验证码");
const countDownSeconds = ref(60);

const mobileInput = ref<HTMLInputElement | null>(null);
const codeInput = ref<HTMLInputElement | null>(null);
const accountInput = ref<HTMLInputElement | null>(null);
const passwordInput = ref<HTMLInputElement | null>(null);

const loading = ref(false);

onMounted(() => {
  accountInput.value?.focus();
  document.title = "矩客星交易平台";
});

const accountLogin = async () => {
  if (account.value.trim().length == 0) {
    message.error("账号不能为空，请输入账号");
    accountInput.value?.focus();
    return;
  }

  if (password.value.trim().length == 0) {
    message.error("密码不能为空，请输入密码");
    passwordInput.value?.focus();
    return;
  }

  loading.value = true;
  const res: any = await login(account.value, password.value);
  loading.value = false;
  if (res.success) {
    if (res.result.auth) {
      const { user, auth: token } = res.result;
      await store.dispatch("auth/login", { user, token });
      const redirect = (route.query.redirect || "/my-home") as string;
      const buyNumber = route.query.q as string;
      if (buyNumber) {
        sessionStorage.setItem("buyNumber", buyNumber);
        router.go(-1);
      } else {
        router.push(redirect);
      }
    } else {
      message.error("您输入的账号或密码不正确");
    }
  }
};

const handleEnter = async () => {
  if (loginType.value == "account") {
    if (account.value.trim().length == 0) {
      accountInput.value?.focus();
      return;
    }

    if (password.value.trim().length == 0) {
      passwordInput.value?.focus();
      return;
    }
  } else {
    if (!/^1[3456789][0-9]{9}$/.test(mobile.value)) {
      message.error("请正确输入手机号码");
      mobileInput.value?.focus();
      return;
    }
    if (code.value.trim().length == 0) {
      codeInput.value?.focus();
      return;
    }
  }
  await loginHandler();
};

const mobileLogin = async () => {
  if (!/^1[3456789][0-9]{9}$/.test(mobile.value)) {
    message.error("请正确输入手机号码");
    mobileInput.value?.focus();
    return;
  }
  if (code.value.trim().length != 6) {
    message.error("请输入6位验证码");
    codeInput.value?.focus();
    return;
  }

  const res: any = await smsLogin(mobile.value.trim(), code.value.trim());
  if (res.success) {
    if (res.result.auth) {
      const { user, auth: token } = res.result;
      await store.dispatch("auth/login", { user, token });
      const redirect = (route.query.redirect || "/my-home") as string;
      router.push(redirect).then(() => {
        window.location.reload();
      });
    } else {
      message.error("验证码登录失败，请重试");
    }
  }
};

const register = () => {
  router.push("/register");
};

const loginHandler = async () => {
  if (loginType.value == "account") {
    await accountLogin();
  } else {
    await mobileLogin();
  }
};

/**
 * 选择登录类型
 * @param type
 */
const swicthLoginType = (type: LoginType) => {
  loginType.value = type;
};

/**
 * 获取短信验证码
 */
const getCodeHandler = async () => {
  if (!/^1[3456789][0-9]{9}$/.test(mobile.value)) {
    message.error("请输入正确手机号码");
    mobileInput.value?.focus();
    return;
  }

  disableButton.value = true;
  codeText.value = `重发 (${countDownSeconds.value}s)`;

  const intervalId = setInterval(() => {
    countDownSeconds.value--;
    codeText.value = `重发 (${countDownSeconds.value}s)`;
    if (countDownSeconds.value === 0) {
      clearInterval(intervalId);
      disableButton.value = false;
      codeText.value = "获取验证码";
      countDownSeconds.value = 60;
    }
  }, 1000);

  const result: any = await getCode(mobile.value);
  if (result.success) {
    message.success("短信已发送，请注意查收");
  } else {
    message.error(result.message);
  }
};
</script>

<style lang="scss" scoped>
.login-header {
  width: 100%;
  height: 28px;
  font-weight: 500;
  font-size: 20px;
  color: #262626;
  line-height: 28px;
  text-align: center;
  font-style: normal;

  .login-content {
    font-weight: 500;
    font-size: 20px;
    color: #262626;
    line-height: 28px;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 48px;
  }
}

.code-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px 0 0 -10px;

  .code-input-left {
    width: 200px;
    height: 42px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #d7d7d7;
  }

  .code-input-right {
    width: 150px;
    height: 42px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 10px;
    color: #ffffff;
    outline: none;
    border: none;

    &.normal-button {
      background: #2353f3;
      &:hover {
        background-color: #f66;
      }
    }

    &.disabled-button {
      background: #d7d7d7;
      &:hover {
        background-color: #d7d7d7;
      }
    }
  }
}

.button-handle {
  width: 360px;
  height: 42px;
  background: #2353f3;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-left: 40px;
  color: #ffffff;
  outline: none;
  border: none;
  &:hover {
    background-color: #f66;
    color: #ffffff;
  }
}

.login-type {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 196px;
  height: 38px;
  background: #f5f5f5;
  border-radius: 3px;
  margin-left: 40px;
  margin-bottom: 30px;
  margin-top: 49px;
  outline: none;
  user-select: none;

  .login-item {
    width: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    font-style: normal;

    &.login-active {
      color: #2353f3;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
      border-radius: 3px;
    }

    .login-text {
      width: 56px;
      height: 18px;
    }
  }
}

.user-account {
  width: 360px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  background: url("../../../assets//images/user.png") no-repeat 12px 16px;
  background-position: 10px;
  padding-left: 40px;
  margin-left: 40px;
}

.user-password {
  width: 360px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  background: url("../../../assets//images/password.png") no-repeat 12px 16px;
  background-position: 10px;
  padding-left: 40px;
  margin: 30px 0 0 40px;
}

.phone-input {
  width: 360px;
  height: 42px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #d7d7d7;
  background: url("../../../assets//images/mobile.png") no-repeat 12px 16px;
  background-position: 10px;
  padding-left: 40px;
  margin-left: 40px;
}

.body-content {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #cedcee;

  .title {
    width: 200px;
    height: 17px;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
    line-height: 17px;
    text-align: left;
    font-style: normal;
    margin-left: 20px;
    letter-spacing: 4px;
  }

  .logo {
    display: flex;
    position: relative;
    width: 200px;
    height: 60px;
    top: 20px;
    left: 20px;

    img {
      margin-right: 10px;
      width: 122px;
      height: 34px;
    }

    .version {
      margin-top: 5px;
      width: 64px;
      height: 24px;
      line-height: 24px;
      border-radius: 3px;
      border: 1px solid #262626;
      font-weight: 400;
      font-size: 13px;
      text-align: center;
      font-style: normal;
    }
  }
  .login {
    position: fixed;
    top: 17vh;
    left: 70vw;
    width: 440px;
    height: 580px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.09);
    border-radius: 16px;
    z-index: 10;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 80px;
    line-height: 80px;
    text-align: center;
    color: #595959;
    font-weight: 500;
    font-size: 16px;
  }

  @media screen and (max-height: 780px) {
    .footer {
      position: fixed;
      top: 710px;
    }
  }

  .block_2 {
    margin-left: 40px;
    margin-bottom: 30px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 4px;
    width: 360px;
    height: 42px;
    border: 1px solid rgba(215, 215, 215, 1);
    margin: 30px 0 0 40px;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
  }

  .justify-start {
    display: flex;
    justify-content: flex-start;
  }

  .align-center {
    display: flex;
    align-items: center;
  }

  .split-line {
    position: relative;
    left: 73px;
    top: -1px;
    width: 1px;
    height: 16px;
  }
  .split-line2 {
    position: relative;
    left: -328px;
    top: 71px;
    width: 1px;
    height: 16px;
  }
}

.register {
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  margin: 20px 45px 0 0;
  cursor: pointer;
  align-items: center;
  color: #2353f3;
}

.group_3 {
  height: 896px;
  width: 1252px;
}

.image-wrapper_2 {
  height: 896px;
  width: 1252px;
}

.image_1 {
  width: 77px;
  height: 20px;
  position: relative;
  top: 325px;
  left: 620px;
  z-index: 1;
}

.image_2 {
  width: 607px;
  height: 613px;
  position: relative;
  top: 213px;
  left: -300px;
}

.image-wrapper_3 {
  width: 381px;
  height: 852px;
}

.image_3 {
  width: 381px;
  height: 594px;
}

.image_4 {
  width: 284px;
  height: 312px;
  margin: -54px 0 0 97px;
}
</style>
